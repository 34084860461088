
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const top100Films = [
    { label: 'Option_1', value: 1 },
    { label: 'Option_2', value: 2 },
    { label: 'Option_3', value: 3 },
];



export default function Select(props) {
  return (
    <div>
      <Autocomplete
        {...props}
        
        disabled={props.isReadonly ?? false}
        multiple={props.multiple}
        value={props.multiple ? props.value : props.value?.[0]}
        id="tags-outlined"
        options={props.option}
        getOptionLabel={(option) => option.label}
        defaultValue={props.defaultValue}
        filterSelectedOptions
        onChange={(e, newValue) => props.handleselect(newValue)}
        size={props.size}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            variant="outlined"
            label={props.label}
            required={props.isrequired}
            placeholder={props.placeholder}
          />
        )}
      />
    </div>
  );
}



Select.propTypes = {
   isReadonly: PropTypes.bool,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.array,
    option: PropTypes.array, // EX: [{value: "", label: ""}],
    handleselect: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium']),
    error: PropTypes.bool,
    value: PropTypes.array //{value: "", label: ""}
}

Select.defaultProps = {
    multiple: false,
    label: "",
    placeholder: "",
    defaultValue: [],
    option: top100Films,
    size: "small",
    handleselect: () => {},
    error: false,
    value: []
}

