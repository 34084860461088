import React from 'react';
import { makeStyles,Checkbox,FormControl,FormGroup,FormControlLabel,Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root:{
        '& .MuiFormControlLabel-label':{
            textTransform:'capitalize'
        }
    }
}));
const CheckBox = ({
    option = [],
    checked = [],
    handleChange = false,
    direction = 'column',
    heading,
    isReadonly=false,
    isrequired
}) => {

    const classes = useStyles();

    const isIamChecked = (data) => {
        
        if (checked.indexOf(data.value) > -1) {
          return true
        }
        else {
          return false
        }
      }

      const handleClick = (value) =>{
        
        if(checked.length === 0){
            handleChange([...checked,value])
        }
        else{
            if(checked.find(i=>i === value) !== undefined){
                const filteredData = checked.filter(i=>i!==value);
                handleChange(filteredData)
            }
            else{
                handleChange([...checked,value])
            }
        }
        

      }

    return (
       <div className={classes.root}>
            <Typography variant="body1">{heading} {isrequired && <Typography variant="caption" style={{color:"red"}}>*</Typography>}</Typography>

           <FormControl component="fieldset">
            <FormGroup row={direction === 'row' ? true : false}>
            {
                option && option.map((data,index)=>{
                    return(
                            <FormControlLabel
                            value={data.value}
                            control={<Checkbox checked={isIamChecked(data, index)} onChange={()=>handleClick(data.value)} color="primary" disabled={isReadonly ?? false} />}
                            label={data.label}
                            labelPlacement="end"
                        />
                    )
                })
            }
            </FormGroup>
           </FormControl>
       </div>
    )
}
CheckBox.propTypes = {
    option: PropTypes.array, // EX: [{value: "male", label: "male"},{value:'female',label:'female'}],
    checked:PropTypes.array, // ['female','male']
    handleChange:PropTypes.func,
    direction:'row'|'column',
    heading:PropTypes.string
}

export default CheckBox;

// this.state = {
//     value:['female']
//   }

// options = [
//     {
//       value:'male',
//       label:'male',
//     },
//     {
//       value:'female',
//       label:'female',
//     },
//     {
//       value:'others',
//       label:'others',
//     },
//   ];
  

//   handleChange = (item) => {
    
//     if (this.state.value.indexOf(item.value) > -1) {
//       const filteredData = this.state.value?.filter(i => i !== item.value);
//       this.setState({
//         ...this.state,value:filteredData
//       });

//     }
//     else {
      
//       this.setState({
//         ...this.state,
//         value:[...this.state.value, item.value]
//       })
//     }

// <CheckBox 
    // option={this.options}
    // checked={this.state.value}
    // handleChange={this.handleChange}
    // direction="row"
// />