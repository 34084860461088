import React from "react";
import {
  makeStyles,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  TextField,
} from "@material-ui/core";
// import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      backgroundColor: '#E5EAF4',
      minWidth: 150,
    }
  },
  addColumn: {
    '& .MuiIconButton-root': {
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }
  }
}));
const CustomTableFormRender = (props) => {

  const classes = useStyles();

  const [tableData, setTableData] = React.useState(props?.tableData?.map(_=>_) ?? [])

  React.useEffect(()=>{
    props?.data?.table_scheme?.row?.map((rowData,rowIndex)=>{
      props?.data?.table_scheme?.column?.map((colData,columnIndex)=>{
          let checkboxId=document.getElementById(`${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`);
          let inputId=document.getElementById(`${props?.data?.id}-input-${rowIndex}-${columnIndex}`);
          let dateId=document.getElementById(`${props?.data?.id}-date-${rowIndex}-${columnIndex}`);
          if(checkboxId){
              checkboxId.value="";
          }
          if(inputId){
              inputId.value="";
          }
          if(dateId){
              dateId.value="";
          }
          return colData
     })
     return rowData
 })
    setTableData(props?.tableData?.map(_=>_) ?? [])
    // eslint-disable-next-line
},[props?.tableData])

  const onChangeValue = (value, rowIndex, columnIndex) => {
    let data = tableData?.[rowIndex]?.map(_=>_) ?? [];
    data[columnIndex] = value;
    tableData[rowIndex] = data;
    setTableData(tableData);
    props?.handleChange({
      tableData,
      table_scheme: props?.data?.table_scheme
    })
  }
  return (
    <div className={classes.root}>
      <div style={{display:"flex"}}>{props.heading && <Typography variant="body1" gutterBottom>
        {props.heading} 
      </Typography>}
      {props.isrequired && <Typography variant="caption" style={{color:"red"}}>*</Typography>}</div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                props?.data?.table_scheme?.column && props?.data?.table_scheme?.column?.map((colData, index) => {
                  return (
                    <TableCell key={index} variant="head">
                      <Typography variant="body1">
                        {colData.name}
                      </Typography>
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props?.data?.table_scheme?.row && props?.data?.table_scheme?.row?.map((rowData, rowIndex) => {
                return (
                  <TableRow key={rowIndex}>
                    {
                      props?.data?.table_scheme?.column && props?.data?.table_scheme?.column?.map((colData, columnIndex) => {
                        switch (colData.ui_component) {
                          case 'check_box':
                            return (
                              <TableCell key={columnIndex}>
                                <Checkbox
                                  id={`${props?.data?.id}-checkbox-${rowIndex}-${columnIndex}`}
                                  checked={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) => onChangeValue(event.target.checked, rowIndex, columnIndex)}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                  disabled={props.isReadonly ?? false}
                                />
                              </TableCell>
                            )
                          case 'input_text':
                            return (
                              <TableCell>
                                <TextField
                                  id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) => onChangeValue(event.target.value, rowIndex, columnIndex)}
                                  inputProps={{ readOnly: props.isReadonly ?? false }}
                                />
                              </TableCell>
                            )
                            case 'date':
                              return(
                                <TableCell>
                                   <form noValidate>
                                      <TextField
                                        id={`${props?.data?.id}-date-${rowIndex}-${columnIndex}`}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={tableData?.[rowIndex]?.[columnIndex]}
                                        onChange={(event) => onChangeValue(event.target.value, rowIndex, columnIndex)}
                                        inputProps={{ readOnly: props.isReadonly ?? false }}
                                      />
                                    </form>
                                </TableCell>
                              )
                          default:
                            return (
                              <TableCell key={columnIndex}>
                                <TextField
                                  id={`${props?.data?.id}-input-${rowIndex}-${columnIndex}`}
                                  value={tableData?.[rowIndex]?.[columnIndex]}
                                  onChange={(event) => onChangeValue(event.target.value, rowIndex, columnIndex)}
                                  inputProps={{ readOnly: props.isReadonly ?? false }}
                                />
                              </TableCell>
                            )
                        }
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
//  CustomTableFormRender.propTypes = {

//  };
export default CustomTableFormRender;

