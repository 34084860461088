import React from 'react'
import { Helmet } from 'react-helmet'
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Avatar,
  AppBar,
  Badge,
  Toolbar,
  Popover
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HomeIcon from '@material-ui/icons/Home'
import PropTypes from 'prop-types'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import PersonIcon from '@material-ui/icons/Person'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Routes } from '../../router/routes'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    height: '64px',
    position: 'sticky'
  },
  root1: {
    height: '64px',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1000,
      boxShadow: 'none'
    }
  },
  iconStyle: {
    color: theme.palette.secondary.main,
    fontSize: 25
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  headerName: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.4
  },
  headerNameSubText: {
    fontSize: '10px',
    lineHeight: 1.8
  },
  mainHeader: {
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px'
  },
  leftPosition: {
    position: 'absolute',
    left: 20,
    color: '#000',
    fontSize: 20,
    cursor: 'pointer'
  },
  avatarStyle: {
    width: 34,
    height: 34,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    backgroundColor: '#D1CEF4',
    textTransform: 'uppercase'
  },
  typography: {
    padding: '8px 15px 1px',
    cursor: 'pointer'
  }
}))
export const Header = ({
  title = 'Human Circle',
  subTitle = '',
  showCloseIcon = false,
  showHomeIcon = false,
  showNotificationIcon = false,
  showAvatar = false,
  showBackIcon = false,
  avatarText = '',
  avatarAlt = '',
  avatarSrc = '',
  isNoHelmat,
  props
}) => {
  const classes = useStyles()
  let history = useHistory()
  const checkJustifyContent = () => {
    if (showCloseIcon || showHomeIcon || showNotificationIcon || showAvatar) {
      return 'space-between'
    } else {
      return 'center'
    }
  }

  const routerTo = path => {
    history.push(path)
  }

  const goBack = () => {
    history.goBack()
  }

  const goHome = () => {
    history.push('/')
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const logout = () => {
    localStorage.clear()
    history.push('/')
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div
      className={`${classes.root} ${
        window.location.pathname === '/subscription_request' ||
        window.location.pathname === '/subscription_categorys'
          ? classes.root1
          : ''
      }`}
    >
      {!isNoHelmat && (
        <Helmet>
          <html lang='en' />
          <title>OneSoul</title>
          <meta name='description' content={'One solue to help you out.'} />
          <link rel='canonical' href={window.location.href} />
        </Helmet>
      )}
      <AppBar
        position={'absolute'}
        color={'inherit'}
        className={classes.mainHeader}
      >
        <Toolbar
          variant={'regular'}
          // style={{
          //   paddingRight: 0
          // }}
        >
          <Grid
            container
            direction='row'
            alignItems='center'
            justify={checkJustifyContent()}
          >
            {showBackIcon && (
              <ArrowBackIosIcon
                onClick={() => goBack()}
                className={classes.leftPosition}
              />
            )}
            <Grid
              item
              alignItems={'center'}
              style={{
                display: 'flex',
                marginLeft:
                  showBackIcon &&
                  checkJustifyContent() === 'space-between' &&
                  30
              }}
            >
              {/* <div onClick={goHome} style={{cursor:"pointer"}}> */}
              {/* <FavoriteBorderRoundedIcon color={"primary"} fontSize={"large"} style={{ marginRight: 12 }} /> */}
              {/* </div> */}
              <div
                style={{
                  margin: 'auto'
                }}
              >
                <img
                  alt='img'
                  src='/images/logo.png'
                  style={{
                    width: '130px',
                    height: '34px',
                    cursor: 'pointer'
                  }}
                  onClick={goHome}
                />
                {/* {title && <Typography variant={"subtitle1"} className={classes.headerName}>{title}</Typography>}
                {subTitle && <Typography variant={"subtitle2"} color={"textSecondary"} className={classes.headerNameSubText}>{subTitle}</Typography>} */}
              </div>
            </Grid>
            <Grid item className={classes.item}>
              {showCloseIcon && (
                <IconButton>
                  <CloseIcon
                    onClick={() =>
                      localStorage.getItem('isSubscribed')
                        ? history.push('/subscription_request')
                        : window.location.pathname.includes(
                            '/request_verification'
                          )
                        ? goBack()
                        : localStorage.getItem('userId')
                        ? history.push('/requests')
                        : history.push('/')
                    }
                    className={classes.iconStyle}
                  />
                </IconButton>
              )}
              {showHomeIcon && (
                <IconButton
                  onClick={() =>
                    localStorage.getItem('isSubscribed')
                      ? history.push('/subscription_request')
                      : routerTo(Routes.requests)
                  }
                >
                  <HomeIcon className={classes.iconStyle} />
                </IconButton>
              )}
              {showNotificationIcon && (
                <IconButton>
                  <Badge
                    variant='dot'
                    color='primary'
                    onClick={() => routerTo(Routes.notification)}
                  >
                    <NotificationsNoneIcon className={classes.iconStyle} />
                  </Badge>
                </IconButton>
              )}
              {showAvatar && (
                <Avatar
                  className={classes.avatarStyle}
                  alt={avatarAlt}
                  src={avatarSrc}
                  style={{
                    marginRight: 6
                  }}
                  onClick={handleClick}
                >
                  {avatarText ? avatarText : <PersonIcon />}
                </Avatar>
              )}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Typography
                  variant='subtitle2'
                  className={classes.typography}
                  onClick={() => history.push('/profile')}
                >
                  Profile
                </Typography>
                <Typography
                  variant='subtitle2'
                  style={{ marginBottom: 8 }}
                  className={classes.typography}
                  onClick={() => logout()}
                >
                  Logout
                </Typography>
              </Popover>
            </Grid>
            {/* <Divider /> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}
Header.propTypes = {
  title: PropTypes.string
}
