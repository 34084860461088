/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-03-30
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor:"#F3F5F9",
    padding: theme.spacing(2)
  },
  note: {
    backgroundColor:"#FCE3F1",
    padding: theme.spacing(2),
    borderRadius: 10,
    // fontStyle: "italic",
  },
}));
const Input = (props) => {
  const classes = useStyles();
  return (
    <Typography
      className={props.notes ? classes.note : classes.label}
      variant="body2"
    >
      {props.text}
    </Typography>
  );
};
Input.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  textAlign: PropTypes.string,
  notes: PropTypes.bool,
};
Input.defaultProps = {
  text: "text",
  textAlign: "left",
  notes: false,
};
export default Input;
