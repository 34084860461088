/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-02-15
 * @desc Input component
 */

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  wrapper: {
    root: {},
    '& .MuiTextField-root': {
      background: '#fff'
    }
  }
}))
const numberOnly = e => {
  if ((e.which >= 48 && e.which <= 57) || e.which === 45) {
    return true
  } else {
    e.preventDefault()
    return false
  }
}
const Input = props => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <TextField
        type={props.type !== 'number' && props.type}
        className={classes.root}
        id={props.id}
        label={props.label}
        required={props.isrequired}
        variant={'outlined'}
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          readOnly: props.isReadonly ?? false,
          maxLength:
            (props.label === "What's your contact number?" ||
              props.label === "What's his/her mobile number?") &&
            10
        }}
        // size='small'
        multiline={props.multiline}
        rows={5}
        rowsMax={10}
        onKeyPress={e => props.type === 'number' && numberOnly(e)}
        onChange={e => props.onChange(e.target.value)}
        value={props.value}
      />
    </div>
  )
}
Input.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  isReadonly: PropTypes.bool
}
Input.defaultProps = {
  label: 'label',
  //   variant: "outlined",
  multiline: false,
  type: 'text'
}
export default Input
