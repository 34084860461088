import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import { withAllContexts } from '../../HOCs'
import { Header } from '../../components'
import { FormRenderer } from '..'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { getLocal, setLocal } from '../../funcations/common'
import Config from '../../config'
import CONFIG from '../../config'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20
  }
})

class RequestDetailsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {}

  onSubmitBtnClicked = async state => {
    
    state.mobileNo = this.giveMeMobileNumber(state)
    let url = Config.api_url + 'response/isAlreadyExists',
      method = 'post',
      body = {
        request_id: this.props.location.state.request_id,
        response_type: this.props.location.state.response_type,
        mobile: state.mobileNo
      }
    NetworkCall(url, method, body)
      .then(res => {
        if (res.data.isFoundAlready) {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false
          })
          this.props.alert.setSnack({
            ...this.props.alert.alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Sorry! You can't do this again.",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        } else {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: true,
            message: 'Sending OTP...'
          })

          state.form_id = this.props.match.params.id

          if (state.mobileNo.length < 10) {
            this.props.alert.setSnack({
              ...this.props.alert.alert,
              open: true,
              severity: AlertProps.severity.warning,
              msg: 'Please Enter Valid Contact Number!',
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center
            })

            this.props.backDrop.setBackDrop({
              ...this.props.backDrop,
              open: false
            })

            return false
          }

          let body = { mobile: state.mobileNo }

          NetworkCall(
            CONFIG.api_url + '/notification/otp/send',
            NetWorkCallMethods.post,
            body
          )
            .then(res => {
              this.props.backDrop.setBackDrop({
                ...this.props.backDrop,
                open: false
              })
              this.props.history.push({
                pathname: Routes.OtpVerifyResponse,
                state: {
                  ...state,
                  ...this.props?.location?.state,
                  reqID: this.props.location.state.reqID,
                  copyLinkURl:this.props?.location?.state?.copyLinkURl
                }
              })
              setLocal('response_data', state?.answer)
            })
            .catch(error => {
              this.props.backDrop.setBackDrop({
                ...this.props.backDrop,
                open: false
              })
              this.props.alert.setSnack({
                ...this.props.alert.alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: 'Something went wrong, Unable to send OTP',
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
              })
            })
        }
      })
      .catch(error => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false })
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Sorry! You can't do this again.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
  }

  giveMeMobileNumber = state => {
    let mobileNo = ''

    state?.selected_form?.framed_questions?.map(qn => {
      if (qn.ui_component === 'input_number' || qn.tag === 'Mobile Number') {
        mobileNo = state?.answer?.[qn.id]?.answer
      }
      return qn
    })

    return mobileNo
  }

  render () {
    const { classes } = this.props
    const checkshowdata = this.props.location.state.detail
    const checkshowdataid = this.props.location.state.reqID
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography variant='h6' className={classes.title}>
                {checkshowdata === "I've Verified" &&
                  'Hope you got in touch with the contact to mark it as verified.'}
                {checkshowdata === 'I can help' &&
                  'Please provide your contact details to share with them.'}
                {checkshowdata === 'It is fulfilled/closed' &&
                  'Please provide your details to inform them to close.'}
              </Typography>
              <FormRenderer
                checkcheckshowdata={true}
                onSubmitBtnClicked={this.onSubmitBtnClicked}
                submitBtnName={
                  (checkshowdata === "I've Verified" && 'Mark As Verified') ||
                  (checkshowdata === 'I can help' && 'Share') ||
                  (checkshowdata === 'It is fulfilled/closed' && 'Submit')
                }
                detaildata={checkshowdata}
                checkshowdataid={checkshowdataid}
                answer={getLocal('response_data')}
                showCheckBox={true}
                checkBoxText={
                  'I hereby declare that the above mentioned information is true to the best of my knowledge and accept to share contact details for the cause.'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles)(withAllContexts(RequestDetailsComponent))
)
