import React from 'react';
import { Grid, makeStyles,Typography,Button,TextField,Card} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        flexDirection:'column',
        height:'100%'
    },
    title:{
        marginBottom:theme.spacing(3),
        fontSize:24,
        fontWeight:700
    },
    textFieldStyle:{
        marginBottom:theme.spacing(3),
        '& .MuiInputBase-input':{
            background:'#fff',
            borderRadius:15, 
            height:35
        },
        '& fieldset':{
            border: '1px solid #00000033',
            borderRadius:15
        }
    },
    noteSection:{
        background:'#FFE5CF',
        padding:theme.spacing(1),
        marginBottom:theme.spacing(3),
        borderRadius:8,
        '& .MuiTypography-subtitle1':{
            lineHeight:1.5,
            marginTop:4,
            color:'#685F5F',
        },
        '& .MuiTypography-body1':{
            color:'#FF8800',
        },
    },
    label:{
        marginBottom:4
    },
    buttonStyle:{
        borderRadius:30,
        height:60,
        marginBottom:theme.spacing(1)
    }
  }));

export const RequestDetails = ({
    title="",
    textField1Text="",
    textField1Label="",
    textField2Text="",
    textField2Label="",
    buttonName="",
    buttonAction=null,
    showNote=false,
    noteTitle='',
    noteDescription=''
}) =>{

    const classes = useStyles();

    const [state,setState] = React.useState({
        name:textField1Text ?? '',
        contactNumber:textField2Text ?? ''
    })

    const onChange = (value,key) =>{
        setState({
            ...state,[key]:value
        })
    }

    const share = () =>{
        buttonAction && buttonAction(state)
    }

   return(
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.textFieldStyle}>
            <Typography className={classes.label} variant="subtitle2">{textField1Label}</Typography>
                <TextField
                    value={state.name}
                    onChange={(e) => onChange(e.target.value,'name')}
                    fullWidth
                    // label={textField1Label}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: false}}
                    // error={val?.error?.labelError ?? false}
                    // helperText={val?.error?.labelError ? "Please give the question (must be more then two character)" : ""}
                    />
            </Grid>
            <Grid item xs={12} className={classes.textFieldStyle}>
                <Typography className={classes.label} variant="subtitle2">{textField2Label}</Typography>
                <TextField
                    value={state.contactNumber}
                    onChange={(e) => onChange(e.target.value,'contactNumber')}
                    fullWidth
                    // label={textField2Label}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: false}}
                    // error={val?.error?.labelError ?? false}
                    // helperText={val?.error?.labelError ? "Please give the question (must be more then two character)" : ""}
                    />
            </Grid>
            <Grid item xs={12}>
              {
                  showNote && <div >
                      <Card className={classes.noteSection}>
                        <Typography variant="body1">{noteTitle}</Typography>
                        <Typography variant="subtitle1">{noteDescription}</Typography>
                      </Card>
                  </div>
              }
            </Grid>
        </Grid>
        <Grid container style={{flex:1,alignItems:'flex-end'}}>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" className={classes.buttonStyle} onClick={share}>{buttonName}</Button>
            </Grid>
        </Grid>
    </div>
   )
}