import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  DialogContentText,
  Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
const useStyles = makeStyles(theme => ({
  root: {},
  dialog: {
    '& .MuiDialogContentText-root': {
      marginBottom: 0
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden'
    },
    width: 340,
    height: 'auto',
    // height: 336,
    borderRadius: 10,
    '& .MuiDialogActions-root': {
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        '& .MuiButtonBase-root': {
          width: '100%',
          '&:not(:first-child)': {
            marginTop: '10px'
          }
        }
      }
    }
  },
  title: {
    textAlign: 'center'
  },
  dialogActions: {
    padding: '0px 24px 16px 24px'
  },
  close: {
    marginBottom: 6,
    top: '20px',
    position: 'absolute',
    right: '20px',
    '& svg': {
      borderRadius: 20,
      border: `1.1px solid #8c8a8ae3`,
      cursor: 'pointer',
      float: 'right',
      color: '#8c8a8ae3',
      fontSize: 25
    }
  },
  titlediv: {
    width: '94%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    textAlign: 'left'
  },
  text: {
    textTransform: 'capitalize',
    cursor: 'pointer',
    height: '50px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      height: '50px'
    }
  }
}))

export const DialogComponent = props => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    open: props?.open ?? false,
    title: props?.title ?? '',
    body: props?.body ?? '',
    positiveBtn: props?.positiveBtn ?? 'Ok',
    negativeBtn: props?.negativeBtn ?? 'Cancel',
    negativeBtnDontShow: props?.negativeBtnDontShow ?? false,
    positiveBtnDontShow: props?.positiveBtnDontShow ?? false,
    onOk: () => props?.onOk ?? null,
    onCancel: props?.onCancel ?? null
  })
  React.useEffect(() => {
    setState({
      open: props?.open ?? false,
      title: props?.title ?? '',
      body: props?.body ?? '',
      positiveBtn: props?.positiveBtn ?? 'Ok',
      negativeBtn: props?.negativeBtn ?? 'Cancel',
      negativeBtnDontShow: props?.negativeBtnDontShow ?? false,
      positiveBtnDontShow: props?.positiveBtnDontShow ?? false,
      onOk: () => props?.onOk ?? null,
      onCancel: props?.onCancel ?? null
    })
  }, [props])
  return (
    <div className={classes.root}>
      <Dialog
        open={state.open}
        onClose={state.onCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{
          paper: classes.dialog
        }}
      >
        <div style={{ margin: 'auto' }}>
          <DialogTitle id='alert-dialog-title' className={classes.title}>
            <div className={classes.close}>
              <CloseIcon onClick={props.onCancel} />
            </div>{' '}
            <div className={classes.titlediv}>{state.title}</div>
          </DialogTitle>
          {state.body && (
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {state.body}
              </DialogContentText>
            </DialogContent>
          )}
          {(!Boolean(state?.negativeBtnDontShow) ||
            !Boolean(state?.positiveBtnDontShow)) && (
            <DialogActions className={classes.dialogActions}>
              {!Boolean(state?.positiveBtnDontShow) && (
                <Button
                  onClick={props.onOk}
                  color='primary'
                  variant='contained'
                  className={classes.text}
                >
                  {state.positiveBtn}
                </Button>
              )}
              {!Boolean(state?.negativeBtnDontShow) && (
                <Button
                  onClick={props.onCancel}
                  color='primary'
                  variant='outlined'
                  className={classes.text}
                  style={{
                    marginLeft: 2
                  }}
                >
                  {state.negativeBtn}
                </Button>
              )}
            </DialogActions>
          )}
        </div>{' '}
      </Dialog>
    </div>
  )
}
