import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputAdornment, OutlinedInput, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  margin: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100% !important'
    },
    '& .css-2b097c-container': {
      marginTop: '8px !important'
    },
    '& .css-yk16xz-control': {
      minHeight: '53px !important',
      borderRadius: '4px !important'
    },
    '& .css-1okebmr-indicatorSeparator': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      //   "& .Mui-focused": {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.light}`
      }
      //   },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      //   "& .Mui-focused": {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.light}`
      }
      //   },
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.primary.light}`
        }
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '16px 12px'
    }
  },
  label: {
    marginBottom: 5
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '0.8rem'
  },
  labelsize: {
    fontSize: 17
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(1)
    },
    '& input': {
      background: '#fff'
      // height: '10px'
    },
    '& fieldset': {
      // background:"#fff",
      color: '#000'
    }
  },
  outlinedInput: {
    height: 56,
    background: '#fff',
    '& fieldset': {
      borderRadius: 4,
      // position: "relative",
      // border: `2px solid ${theme.palette.primary.light}`,
      fontSize: 16,
      width: '100%',
      // padding: "16px 12px",
      transition: theme.transitions.create(['border-color', 'box-shadow'])
    }
  }
}))

export const TextFieldAdornment = props => {
  const classes = useStyles(props)

  const { inputLabel, value, handleChange, adornment, isReadonly } = props

  return (
    <div className={classes.margin}>
      <Typography variant='body1' className={classes.label}>
        {inputLabel}
      </Typography>
      <OutlinedInput
        {...props}
        // size={'small'}
        disabled={props.isDisabled}
        // label={null}
        autoComplete={'no'}
        id={`textField-${inputLabel}`}
        value={value}
        onChange={handleChange}
        className={`${classes.root} ${classes.outlinedInput}`}
        endAdornment={
          adornment && (
            <InputAdornment position='end'>{adornment}</InputAdornment>
          )
        }
        aria-describedby='outlined-weight-helper-text'
        inputProps={{
          'aria-label': 'wesdfsdfight',
          'aria-autocomplete': 'none'
        }}
        readOnly={isReadonly}
        // labelWidth={0}
      />
      {props.error && (
        <Typography
          variant='body1'
          component='div'
          className={classes.errorText}
        >
          {props.errorText}
        </Typography>
      )}
    </div>
  )
}
