import React from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Header } from '../../components'
import { withAllContexts } from '../../HOCs'
import { Routes } from '../../router/routes'
import { FormRenderer } from '..'
import CONFIG from '../../config'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { getLocal, setLocal } from '../../funcations/common'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  }
})
class RegistrationForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {}

  onSubmitBtnClicked = async state => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: 'Sending OTP...'
    })

    state.mobileNo = this.giveMeMobileNumber(state)
    state.form_id = this.props.match.params.id
    state.request_type_id = this.props.match.params.request_id
    state.requestTypeName = this.props.location.state?.requestTypeName

    if (state.mobileNo.length < 10) {
      this.props.alert.setSnack({
        ...this.props.alert.alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: 'Please Enter Valid Contact Number!',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false
      })

      return false
    }
    let body = { mobile: state.mobileNo }

    NetworkCall(
      CONFIG.api_url + '/notification/otp/send',
      NetWorkCallMethods.post,
      body
    )
      .then(res => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false })
        this.props.history.push({
          pathname: Routes.otpVerify,
          state: {
            ...state,
            locatioState: this.props.location.state.subscriptionUser,
            selectCategorys: this.props.location.state.selectCategorys
          }
        })
        setLocal('request_data', state?.answer)
      })
      .catch(error => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false })
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Something went wrong, Unable to send OTP',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
  }

  giveMeMobileNumber = state => {
    let mobileNo = ''

    state?.selected_form?.framed_questions?.map(qn => {
      if (qn.ui_component === 'input_number' || qn.tag === 'Mobile Number') {
        mobileNo = state?.answer?.[qn.id]?.answer
      }
      return qn
    })

    return mobileNo
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {/* header */}
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography variant='h6' className={classes.title}>
                {this.props.location?.state?.ismyselef === 0 &&
                  'We need these details to bridge you with people.'}
                {this.props.location?.state?.ismyselef === 1 &&
                  'We need these details to bridge him/her with people.'}
                {this.props.location?.state?.subscriptionUser &&
                  'Please provide your details.'}
              </Typography>
              <FormRenderer
                onSubmitBtnClicked={this.onSubmitBtnClicked}
                submitBtnName={'Next'}
                answer={getLocal('request_data')}
                showCheckBox={true}
                selectCategorysuser={this.props.location?.state?.selectCategorysuser}
                locationState={this.props?.location?.state}
                subscriptionUser={
                  this.props.location.state.subscriptionUser
                    ? this.props.location.state.subscriptionUser
                    : ''
                }
                checkBoxText={
                  'I hereby declare that the above mentioned information is true to the best of my knowledge and accept to share contact details for the cause.'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withAllContexts(RegistrationForm)))
