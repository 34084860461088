import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Chip, OutlinedInput, Typography } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  addButton: {
    textTransform: 'capitalize'
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: '96%'
  },
  root: {
    "& .MuiOutlinedInput-adornedEnd":{
      background: '#fff',
    },
    '& input': {
      background: '#fff',
    },
    '& fieldset': {
      // background:"#fff",
      color: '#000'
    }
  }
}))

export default function CustionAddText (props) {
  const classes = useStyles()
  const [values, setValues] = React.useState('')

  const handleChange = event => {
    setValues(event.target.value)
  }

  const handleClickAdd = () => {
    if (!values) {
      return false
    }

    let object = {
      label: values,
      value: values
    }
    props.options.push(object)
    props.addTag(props.options)
    setValues('')
  }

  const handleDelete = val => {
    let deleted = props.options.filter(vals => vals.value !== val.value)
    props.addTag(deleted)
  }

  const handleKeyUp = event => {
    if (event.keyCode === 13) {
      event.preventDefault()
      handleClickAdd()
    }
  }

  return (
    <div>
      <FormControl  fullWidth variant='outlined'
          className={classes.root}
          >
        <InputLabel htmlFor='outlined-adornment-password'>
          {props.label}{' '}
          {props.isrequired && (
            <Typography variant='caption' style={{ color: 'red' }}>
              *
            </Typography>
          )}
        </InputLabel>
        <OutlinedInput
          readOnly={props.isReadonly ?? false}
          error={props.error}
          fullWidth
          id='outlined-adornment-password'
          type={'text'}
          value={values}
          onKeyUp={handleKeyUp}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position='end'>
              <Button
                onClick={handleClickAdd}
                className={classes.addButton}
                size={'small'}
                color={'primary'}
                variant={'text'}
              >
                + Add
              </Button>
            </InputAdornment>
          }
          labelWidth={props.label.length * 8}
        />
      </FormControl>
      {props.options.length > 0 &&
        props.options.map(val => {
          return (
            <Chip
              className={classes.chip}
              label={val.label}
              onDelete={() => handleDelete(val)}
            />
          )
        })}
    </div>
  )
}

CustionAddText.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array, // [{value: "", label: ""}]
  addTag: PropTypes.func,
  error: PropTypes.bool,
  readOnly: PropTypes.bool
}

CustionAddText.defaultProps = {
  label: '',
  options: [],
  addTag: () => {},
  error: false
}
