import React from 'react'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import {
  Typography,
  Grid,
  withStyles,
  Card,
  CardHeader,
  TextField,
  Button
} from '@material-ui/core'
import { Header } from '../../components'
import { RequestData } from '../../funcations'
import {
  Feedcard
  // , LoadingSection
} from '../../components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withApollo } from 'react-apollo'
import {
  getAllRequest,
  getAllMasterCloseReason,
  getProfile
} from '../../graphql/queries'
import { closeMyRequest } from '../../graphql/mutations'
import { DialogComponent } from '../../components/dialog/index'
import moment from 'moment'
import Config from '../../config'
import { NetworkCall } from '../../networkcall'
import { distance } from '../../utils'
var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}

function errors (err) {
  console.warn(`ERROR(${err.code}): ${err.message}`)
}
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    minHeight: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%',
      minHeight: '100vh'
    },
    position: 'relative'
  },
  subtitle: {
    // marginBottom: '-16px',
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 8
  },
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '20px 0px 0px',
    borderRadius: 20
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 64px)',
      marginTop: 60
    }
  },
  cardDescription: {
    minHeight: 80,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    paddingTop: 15,
    cursor: 'pointer'
  },
  closecardDescription: {
    cursor: 'pointer',
    marginBottom: 8,
    background: `${theme.palette.primary.main}`,
    padding: 0,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    '& .title': {
      fontSize: 14
    },
    '& .head': {
      padding: '14px 10px',
      marginTop: -6
    }
  },
  subtitles: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    fontSize: 13,
    '& .icon': {
      fontSize: 11,
      marginLeft: 6
    }
  },
  nodata: {
    marginTop: 15,
    textAlign: 'center'
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  textcard: {
    background: '#0000000d'
    // marginBottom: 10
  },
  nocomm: {
    color: '#000',
    height: '48vh',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 14
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    height: '66px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#fff',
    // color: '#e72ea6',
    color: '#000',
    // borderTopLeftRadius: 16,
    // borderTopRightRadius: 16,
    padding: '0px 10px',
    cursor: 'pointer',
    borderTop: '1px solid #E0E0E0',
    '& .left': {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      justifyContent: 'center'
    },
    '& .right': {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      justifyContent: 'center'
    },
    '& svg': {
      marginRight: 6
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1000,
      boxShadow: 'none'
    }
  },
  ativefooter: {
    background: '#e72ea633',
    color: '#e72ea6',
    height: '80%',
    borderRadius: 10,
    fontWeight: 800
  },
  filters: {
    // background: '#e72ea633',
    minWidth: 38,
    '&:hover': {
      //   background: '#e72ea633'
    }
  }
})
class SubscriptionRequest extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      closedata: [],
      closeReason: null,
      whywontclose: false,
      sharenote: false,
      close: false,
      location: {},
      selectedReqId: null,
      name: null,
      activefooter: 0,
      offset: 0,
      limit: 10,
      requestedBy: null
    }
  }

  checktochatlist = res => {
    var phoneno = /^\d{10}$/
    if (res?.data?.userProfileById?.firstName?.match(phoneno)) {
      return ''
    } else {
      if (res?.data?.userProfileById?.firstName) {
        return res?.data?.userProfileById?.firstName
      } else {
        return ''
      }
    }
  }

  Fetchfeeds = async () => {
    const state = this.state
    let url = Config.api_url + 'feed/get'
    let method = 'post'
    let newHeader = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'application/json'
    }
    let body = { offset: state.offset, limit: state.limit }
    await NetworkCall(url, method, body, newHeader)
      .then(res => {
        if (res?.data && res?.data?.type === 'Success') {
          let listData = []
          res?.data?.data.map((val, i) => {
            if (val.status === 1 && val.is_active) {
              let json = RequestData(JSON.stringify(Object.values(val.request)))
              let mentionarr = [
                this.boldgrp(json),
                {
                  name: json.mention,
                  img: '/images/patient.svg',
                  nameMap: true
                },
                {
                  name: json.medicine ? json.medicine : null,
                  img: '/images/medicine.svg'
                },
                {
                  name: json.needby
                    ? 'Needed by ' + moment(json.needby).format('LL')
                    : '',
                  img: '/images/Needed.svg'
                },
                {
                  name:
                    (json.age ? json.age + ', ' : '') +
                    (json.gender ? json.gender : ''),
                  img: '/images/calendar (1).svg'
                },
                {
                  name: json.cantactnumber
                    ? json.cantactnumber
                    : json.mno
                    ? json.mno
                    : '',
                  img: '/images/cell-phone.svg',
                  phone: true
                },
                { name: json.address, img: '/images/location.svg' }
              ]
              mentionarr = mentionarr.filter(
                val => val.name && val.name.length > 0
              )
              listData.push({
                updateddate: val.updatedAt,
                date: new Date(val.createdAt).toDateString(),
                dates: val.createdAt,
                created_by: val.created_by,
                title: json.name + ' needs ' + val.master_request_type,
                titleName: json.name,
                master_request_type: val.master_request_type,
                nameList: mentionarr,
                isemergency: json.ems,
                requestedBy: val?.requested_by,
                mobile_no: json.mno,
                shareUrl: val?.requestType,
                veriyCount: val.verified_count ? val.verified_count : 0,
                commentCount: val.comment_count ? val.comment_count : 0,
                HelpCount: val.can_help_count ? val.can_help_count : 0,
                distance: Math.round(
                  distance(
                    json.location.latitude,
                    json.location.longitude,
                    this.state.location.latitude,
                    this.state.location.longitude,
                    'K'
                  )
                ),
                requestLocation: json.location,
                ...val
              })
              return val
            }
          })
          this.setState({
            list: listData,
            requestedBy: listData[0]?.requestedBy
          })
        }
      })
      .catch(error => {})
  }
  getPrifielDetails = () => {
    this.props.client
      .query({
        query: getProfile,
        variables: { id: localStorage.userId },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        if (res?.data?.userProfileById) {
          this.setState({
            name: this.checktochatlist(res)
          })
        }
      })
  }
  getCurrentLocation = () => {
    const myThis = this
    if (navigator?.geolocation) {
      navigator?.permissions
        ?.query({ name: 'geolocation' })
        ?.then(function (result) {
          if (result.state === 'granted') {
            console.log(result.state)
            //If granted then you can directly call your function here
            navigator.geolocation?.getCurrentPosition(function (position) {
              let { list = {} } = myThis.state
              if (list) {
                list.distance = Math.round(
                  distance(
                    list?.requestLocation?.latitude,
                    list?.requestLocation?.longitude,
                    myThis.state?.location?.latitude,
                    myThis.state?.location?.longitude,
                    'K'
                  )
                )
                myThis.setState({ location: position.coords, list })
              } else {
                myThis.setState({ location: position.coords })
              }
            })
          } else if (result.state === 'prompt') {
            navigator.geolocation?.getCurrentPosition(
              function (position) {
                let { list = {} } = myThis.state
                if (list) {
                  list.distance = Math.round(
                    distance(
                      list?.requestLocation?.latitude,
                      list?.requestLocation?.longitude,
                      myThis.state?.location?.latitude,
                      myThis.state?.location?.longitude,
                      'K'
                    )
                  )
                  myThis.setState({ location: position.coords, list })
                } else {
                  myThis.setState({ location: position.coords })
                }
              },
              errors,
              options
            )
          } else if (result.state === 'denied') {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state)

            navigator.geolocation?.getCurrentPosition(
              function (position) {
                let { list = {} } = myThis.state
                if (list) {
                  list.distance = Math.round(
                    distance(
                      list?.requestLocation?.latitude,
                      list?.requestLocation?.longitude,
                      myThis.state?.location?.latitude,
                      myThis.state?.location?.longitude,
                      'K'
                    )
                  )
                  myThis.setState({ location: position.coords, list })
                } else {
                  myThis.setState({ location: position.coords })
                }
              },
              errors,
              options
            )
          }
        })
    } else {
      console.log('Sorry Not available!')
    }
  }
  componentDidMount () {
    this.getCurrentLocation()
    if (!localStorage.userId) {
      return false
    }
    this.Fetchfeeds()
    this.props.client
      .query({
        query: getAllMasterCloseReason,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setState({
          closedata: res.data.allMasterRequestCloseReasons.nodes
        })
      })

    // this.fetchAllData()
  }
  boldgrp = json => {
    if (json.units) {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ', ' : '') +
          json.units +
          ' Units',
        img: '/images/blood.svg'
      }
    } else {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ' ' : '') +
          (json.units ? json.units : ''),
        img: '/images/blood.svg'
      }
    }
  }
  fetchAllData = () => {
    this.props.client
      .query({
        query: getAllRequest,
        variables: {
          userId: localStorage.userId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []
        res.data.allRequests.nodes.map((val, i) => {
          if (val.isActive) {
            let json = RequestData(val.request)
            let mentionarr = [
              this.boldgrp(json),
              { name: json.mention, img: '/images/patient.svg', nameMap: true },
              {
                name: json.medicine ? json.medicine : null,
                img: '/images/medicine.svg'
              },
              {
                name: json.needby
                  ? 'Needed by ' + moment(json.needby).format('LL')
                  : '',
                img: '/images/Needed.svg'
              },
              {
                name:
                  (json.age ? json.age + ', ' : '') +
                  (json.gender ? json.gender : ''),
                img: '/images/calendar (1).svg'
              },
              {
                name: json.cantactnumber
                  ? json.cantactnumber
                  : json.mno
                  ? json.mno
                  : '',
                img: '/images/cell-phone.svg',
                phone: true
              },
              { name: json.address, img: '/images/location.svg' }
            ]
            mentionarr = mentionarr.filter(
              val => val.name && val.name.length > 0
            )
            let cat = val?.masterRequestTypeByRequestType?.requestType
            if (cat && cat.toLocaleLowerCase().includes('others')) {
              cat = 'Help'
            }
            listData.push({
              updateddate: val.updatedAt,
              date: new Date(val.createdAt).toDateString(),
              dates: val.createdAt,
              title: json.name + ' needs ' + cat,
              titleName: json.name,
              nameList: mentionarr,
              isemergency: json.ems,
              mobile_no: json.mno,
              shareUrl: val.requestType,
              ...val
            })
            return val
          }
        })

        this.setState({
          list: listData
        })
      })
      .catch(e => {
        alert('something went wrong!')
      })
  }

  handleShare = id => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Post',
          text: `Check out our latest request`,
          url: Routes.sharedRequestuse + id
        })
        .then(() => {
          console.log('Successfully shared')
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error)
        })
    }
  }

  handlechoosewhyclose = (_list, i) => {
    const state = this.state
    if (i === 2) {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: false,
        whywontclose: false,
        close: !state.close
      })
    } else {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: !state.sharenote,
        whywontclose: false
      })
    }
  }

  dialogComponentBody = classes => {
    const state = this.state
    if (state.whywontclose) {
      return (
        <div>
          {state.closedata?.map((_list, i) => {
            return (
              <Card
                className={classes.closecardDescription}
                elevation={4}
                onClick={() => this.handlechoosewhyclose(_list, i)}
              >
                <CardHeader
                  className='head'
                  title={<span className='title'>{_list?.name}</span>}
                />
              </Card>
            )
          })}
        </div>
      )
    } else if (state.sharenote) {
      return (
        <div>
          <TextField
            className={classes.textcard}
            type='text'
            fullWidth
            variant='outlined'
            placeholder='Type here...'
            onChange={e => this.setState({ specialNote: e.target.value })}
          />
          <br />
        </div>
      )
    }
  }

  handleopemodle = reqId => {
    const state = this.state
    this.setState({
      selectedReqId: reqId,
      whywontclose: !state.whywontclose
    })
  }

  handleonCancel = () => {
    const state = this.state
    this.setState({
      ...state,
      whywontclose: false,
      sharenote: false,
      close: false
    })
  }

  handleoky = async () => {
    const state = this.state

    await this.props.client
      .mutate({
        mutation: closeMyRequest,
        variables: {
          id: state.selectedReqId,
          closeReason: state?.closeReason?.id,
          updatedBy: localStorage.userId,
          specialNote: state?.specialNote,
          updatedAt: new Date()
        }
      })
      .then(data => {
        this.setState(
          {
            ...state,
            whywontclose: false,
            sharenote: false,
            close: false
          },
          () => {
            if (state.activefooter === 0) {
              this.Fetchfeeds()
            } else {
              this.fetchAllData()
            }
          }
        )
      })
      .catch(error => {
        console.log(error)
      })
    if (!state.whywontclose && state.sharenote) {
      this.setState({
        ...state,
        whywontclose: false,
        sharenote: false,
        close: false
      })
    }
  }
  handleChangefooter = async v => {
    this.setState({
      activefooter: v
    })
    if (v === 0) {
      await this.Fetchfeeds()
    } else {
      this.fetchAllData()
    }
  }
  FilterUpdate = () => {
    this.props.history.push({
      pathname: '/subscription_categorys',
      state: { feed: 'feeds_update' }
    })
  }
  sroolbotm = async e => {
    const target = e.target
    const state = this.state
    if (state.list?.length > 9) {
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        await this.setState({
          ...state,
          offset: state.offset + 10
        })
        this.Fetchfeeds()
      }
    }
  }
  render () {
    const { classes } = this.props
    const {
      list,
      whywontclose,
      sharenote,
      close,
      name,
      activefooter,
      requestedBy
    } = this.state

    return (
      <div className={classes.root}>
        <DialogComponent
          negativeBtnDontShow={whywontclose ? true : false}
          positiveBtnDontShow={whywontclose ? true : false}
          title={
            (whywontclose && 'Why do you want to close?') ||
            (sharenote && 'Share your thank you note (Optional)') ||
            (close && 'Are you sure, would you like to colse the request?')
          }
          open={whywontclose || sharenote || close}
          body={this.dialogComponentBody(classes)}
          onOk={this.handleoky}
          onCancel={this.handleonCancel}
          positiveBtn='Close Request'
          negativeBtn='Cancel'
        />
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header
              showAvatar={true}
              avatarText={name ? name[0] : ''}
              showNotificationIcon={true}
              discription={true}
            />
            <Grid
              className={classes.body}
              onScroll={activefooter === 0 && this.sroolbotm}
            >
              <Card
                onClick={() => this.props.history.push(Routes.registrationType)}
                className={classes.cardDescription + ' ' + classes.backimage1}
                elevation={4}
              >
                <span style={{ fontWeight: 600, fontSize: 18 }}>
                  Do you need help?
                </span>
                <span className={classes.subtitles}>
                  Post and share request
                  <ArrowForwardIosIcon fontSize='small' className='icon' />
                </span>
              </Card>

              <Typography
                variant='h6'
                className={classes.subtitle}
                style={{
                  display: 'flex',
                  justifyContent: activefooter === 0 && 'space-between'
                }}
              >
                {activefooter === 0 ? 'Feed' : 'My Requests'}
                {activefooter === 0 && (
                  <Button
                    className={classes.filters}
                    onClick={() => this.FilterUpdate()}
                  >
                    <img
                      width='20'
                      src='./images/filters.svg'
                      alt='loding...'
                    />
                  </Button>
                )}
              </Typography>
              {/* {list.length === 0 && <LoadingSection />} */}
              {list.length === 0 && (
                <div className={classes.nocomm}>
                  <div>
                    <img
                      src='https://img.icons8.com/clouds/100/000000/send-letter-1.png'
                      alt='loading...'
                      style={{
                        width: 120,
                        height: 120,
                        marginBottom: -20
                      }}
                    />
                    <br />
                    <label style={{ fontSize: 13 }}>No requests yet.</label>
                  </div>
                </div>
              )}
              <div
                style={{
                  marginBottom: '80px'
                }}
              >
                {list?.map(_list => {
                  return (
                    <Feedcard
                      list={_list}
                      handleShare={this.handleShare}
                      handleopemodle={this.handleopemodle}
                      history={this.props.history}
                      feedView={activefooter === 0 ? true : false}
                      showcardslist={
                        (activefooter === 0
                          ? _list.created_by
                          : requestedBy) === localStorage.getItem('userId')
                          ? true
                          : false
                      }
                      dontredirect={
                        activefooter === 0
                          ? _list.created_by !== localStorage.getItem('userId')
                            ? true
                            : false
                          : false
                      }
                      dontShowShare={
                        activefooter === 0 &&
                        _list.created_by !== localStorage.getItem('userId')
                          ? true
                          : false
                      }
                      subUser={true}
                    />
                  )
                })}
              </div>
            </Grid>
            <div className={classes.footer}>
              <div
                className={`left ${activefooter === 0 && classes.ativefooter}`}
                onClick={() => this.handleChangefooter(0)}
              >
                <svg
                  fill={`${activefooter === 0 ? '#e72ea6' : '#000000'}`}
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 26 26'
                  width='20px'
                  height='20px'
                >
                  <path d='M 21.125 1 L 4.875 1 C 2.183594 1 0 3.183594 0 5.875 L 0 19.125 C 0 21.816406 2.183594 24 4.875 24 L 21.125 24 C 23.816406 24 26 21.816406 26 19.125 L 26 5.875 C 26 3.183594 23.816406 1 21.125 1 Z M 22 19 C 22 19.550781 21.550781 20 21 20 L 5 20 C 4.449219 20 4 19.550781 4 19 L 4 18 C 4 17.449219 4.449219 17 5 17 L 21 17 C 21.550781 17 22 17.449219 22 18 Z M 22 13 C 22 13.550781 21.550781 14 21 14 L 5 14 C 4.449219 14 4 13.550781 4 13 L 4 12 C 4 11.449219 4.449219 11 5 11 L 21 11 C 21.550781 11 22 11.449219 22 12 Z M 22 7 C 22 7.550781 21.550781 8 21 8 L 5 8 C 4.449219 8 4 7.550781 4 7 L 4 6 C 4 5.449219 4.449219 5 5 5 L 21 5 C 21.550781 5 22 5.449219 22 6 Z' />
                </svg>
                {/* <img width='20' src='./images/feed.svg' alt='loding...' /> */}
                Feed
              </div>
              <div
                onClick={() => this.handleChangefooter(1)}
                className={`right ${activefooter === 1 && classes.ativefooter}`}
              >
                <svg
                  fill={`${activefooter === 1 ? '#e72ea6' : '#000000'}`}
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  width='24px'
                  height='24px'
                >
                  {' '}
                  <path d='M20,6h-7.172c-0.53,0-1.039-0.211-1.414-0.586l-0.828-0.828C10.211,4.211,9.702,4,9.172,4H4C2.9,4,2,4.9,2,6v12 c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M13.865,15.155l-0.129,0.11c-0.28,0.244-0.741,0.57-1.118,0.826 c-0.373,0.254-0.862,0.254-1.235,0c-0.377-0.256-0.838-0.583-1.118-0.827l-0.13-0.11c-0.642-0.542-2.151-1.815-2.135-3.292 c0.004-0.321,0.079-0.651,0.25-0.986c0.421-0.82,1.175-1.375,2.15-1.377c0.615-0.001,1.35,0.35,1.6,0.746 c0.275-0.396,0.986-0.747,1.6-0.746c0.975,0.001,1.729,0.557,2.149,1.377c0.172,0.335,0.247,0.665,0.25,0.986 C16.016,13.339,14.507,14.613,13.865,15.155z' />
                </svg>
                {/* <img width='20' src='./images/my_requests.svg' alt='loding...' /> */}
                My Requests
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(SubscriptionRequest)))
