import gql from 'graphql-tag';

const createCommend = gql `mutation(
    $id: UUID!
    $comment: String
    $createdBy: UUID
    $createdAt: Datetime
    $requestId: UUID
    $updatedAt: Datetime
    $updatedBy: UUID
  ) {
    createComment(
      input: {
        comment: {
          id: $id
          comment: $comment
          createdBy: $createdBy
          createdAt: $createdAt
          requestId: $requestId
          updatedAt: $updatedAt
          updatedBy: $updatedBy
          isActive: true
        }
      }
    ) {
      comment {
        id
      }
    }
  }
  `

const closeMyRequest = gql `mutation($updatedAt:Datetime, $id: UUID!, $closeReason: Int, $specialNote:String) {
  updateRequestById(
    input: { id: $id, requestPatch: { updatedAt:$updatedAt, closeReason: $closeReason, specialNote : $specialNote, status: 2 } }
  ) {
    request {
      id
    }
  }
}
`

const ReopenRequest = gql `mutation($reqId :UUID!, $updatedAt:Datetime){
  updateRequestById(input : {
    id: $reqId,
    requestPatch : {
      status : 1,
      updatedAt:$updatedAt
    }
  }){
    request{
      id
    }
  }
}`
const Updateprofile = gql `mutation($id :UUID!, $firstName:String) {
  updateUserProfileById(
    input: {
      userProfilePatch: { firstName: $firstName }
      id: $id
    }
  ) {
    clientMutationId
  }
}
`
const UpdateprofileSubscriber = gql `mutation(
  $id: UUID!
  $firstName: String
  $radius: Int
  $requestsPerDay: Int
  $locality: String
  $latitude: Float
  $longitude: Float
) {
  updateUserProfileById(
    input: {
      userProfilePatch: {
        firstName: $firstName
        radius: $radius
        requestsPerDay: $requestsPerDay
        locality: $locality
        latitude: $latitude
        longitude: $longitude
      }
      id: $id
    }
  ) {
    clientMutationId
  }
}

`
const catUpdate=gql`mutation($id: UUID!, $subscripedCategories: [Int]) {
  updateUserProfileById(
    input: {
      userProfilePatch: { subscripedCategories: $subscripedCategories }
      id: $id
    }
  ) {
    clientMutationId
  }
}
`;
export {
  createCommend,
  closeMyRequest,
  ReopenRequest,
  Updateprofile,
  UpdateprofileSubscriber,
  catUpdate
};