import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, CardHeader, withStyles, Button, Avatar, Typography  } from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    padding: '6px 20px',
    boxShadow: '1px 1px 0.4em 2px rgb(0 0 0 / 10%)',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '10px 0px'
  },
  cardheader: {
    padding: 0,
    '& .calbtn': {
      padding: '2px 6px',
      fontSize: 13,
      marginTop: 17,
      '& .callicon': {
        fontSize: 16,
        marginRight: 6
      },
      textTransform: 'capitalize'
    },
    '& .title': {
      color: '#000',
      fontSize: 15,
      fontWeight: 600
    },
    '& .dis': {
      color: '#000'
    },
    '& .MuiCardHeader-action':{
      marginRight:0
    }
  },
  cardDescription: {
    minHeight: 60,
    marginBottom: 8,
    backgroundColor: theme.palette.secondary.contrastText,
    padding: '18px',
    color: '#fff',
    borderRadius: 10,
    fontSize: 20
    // paddingTop: 24
  },
  description:{
    border: '1px solid #eaeaea',
    marginTop: '10px',
    padding: 10,
    background: '#EEEEEE52',
    borderRadius:4,
    '& .MuiTypography-subtitle2':{
      fontSize:13,
      fontWeight:600,
      color:'#9B9B9B'
    },
    '& .MuiTypography-body1':{
      fontWeight:600,
      marginTop:6,
      fontSize:15,
      color:'#000'
    }
  }
})
class Willinghelplistcard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {}
  render () {
    const { classes, data } = this.props;
    return (
      <div className={classes.root}>
        {data?.map(_v => {
          return (
            <Card className={classes.cardDescription} elevation={4}>
              <CardHeader
                className={classes.cardheader}
                avatar={
                  <Avatar className={classes.avatar} src={_v?.img}>
                    {_v?.title[0]}
                  </Avatar>
                }
                action={
                  <a href={'tel:' + _v?.dis} style={{ textDecoration: 'none' }}>
                    <Button
                      className='calbtn'
                      variant='outlined'
                      color='primary'
                    >
                      <CallIcon className='callicon' /> Call
                    </Button>
                  </a>
                }
                title={<span className='title'>{_v?.title}</span>}
                subheader={<span className='dis'>{_v?.dis}</span>}
              />
              {
                _v?.description && _v?.description?.length > 0 &&
                <div className={classes.description}>
                    <Typography variant="subtitle2">Note</Typography>
                    <Typography variant="body1">{_v?.description}</Typography>
                </div>
              }
            </Card>
          )
        })}
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Willinghelplistcard))
