import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Grid, Typography } from '@material-ui/core'
import Willinghelplistcard from '../../components/helpcard/index'
import {
  Header
  //  LoadingSection,
} from '../../components';
import { getAllRequestResponse } from '../../graphql/queries'
import { withApollo } from 'react-apollo'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'hidden',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  back: {
    marginBottom: -6,
    cursor: 'pointer'
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  titlecomments: {
    display: 'flex',
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: 20,
    paddingTop: 20,
    '& div': {
      width: 20,
      height: 20,
      background: theme.palette.secondary.main,
      color: '#fff',
      borderRadius: '50%',
      fontSize: 12,
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop: 2,
      marginLeft: 4,
      // paddingLeft: 6,
      // paddingTop: 2
    }
  },
  nocomm: {
    height: '60vh',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  }
})
class Willinghelp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: []
    }
  }

  componentDidMount () {
    const { requestId } = this.props?.location?.state ?? {}
    this.props.client
      .query({
        query: getAllRequestResponse,
        variables: { requestId },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []
        res.data.allRequestResponses.nodes.map(val => {
          let list = JSON.parse(val.response)
          let data = {}
          Object.values(list).map(val => {
            data[val.tag] = val.answer
            return false
          })
          listData.push({
            title: data['Name'],
            dis: data['Mobile Number'],
            img: data['Name']?.[0],
            description: data['Add Note'] ?? ''
          })
          return false
        })
        this.setState({ list: listData })
      })
  }

  
  render () {
    const { classes } = this.props
    const { list } = this.state
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showBackIcon />
            <Typography variant='h5' className={classes.titlecomments}>
              Can Help<div>{list?.length ?? 0}</div>
            </Typography>
            <Grid className={classes.body}>
              {/* {list.length === 0 && <LoadingSection />} */}
              {list.length === 0 && (
                <div className={classes.nocomm}>
                  No one has pledged to help yet!
                </div>
              )}
              <Willinghelplistcard data={list} />
            </Grid>
            <br />
            <br />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(Willinghelp)))
