import React from 'react'
import { Typography, makeStyles, Button, Card, Avatar } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Config from '../../config'
// import FavoriteIcon from '@material-ui/icons/Favorite'
// import ShareIcon from '@material-ui/icons/Share'
import CallIcon from '@material-ui/icons/Call'
import { Routes } from '../../router/routes'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { getAllResponseType } from '../../graphql/queries'
import { withApollo } from 'react-apollo'
import { withAllContexts } from '../../HOCs'
import { RWebShare } from 'react-web-share'
import { GiveMeShareURL } from '../../utils'
import { RequestData } from '../../funcations'
import { DialogComponent } from '../dialog'
import { NetworkCall } from '../../networkcall'
import moment from 'moment'
const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    borderRadius: 20,
    marginBottom: '20px',
    position: 'relative'
  },
  phoneCall: {
    textDecoration: 'none'
  },
  cardsverifydatestitle: {
    marginBottom: -4,
    float: 'left',
    width: '100%',
    textAlign: 'left'
  },
  cardsverifydates: {
    color: '#fff',
    fontSize: 11,
    float: 'left'
  },
  emergencymain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .emergency': {
      padding: '4px 11px',
      background: '#FF0000',
      color: theme.palette.primary.contrastText,
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    },
    '& .date': {
      color: theme.palette.text.secondary,
      fontSize: 13
    },
    '& .closeddate': {
      //   borderLeft: '1.1px solid #eaeaea',
      //   paddingLeft: 16
    },
    '& .closed': {
      padding: '4px 14px',
      background: '#FF880052',
      color: '#FF8800',
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    }
  },
  username: {
    padding: '14px 0px',
    fontSize: 17,
    fontWeight: 600
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: 14,
    padding: '6px 0px',
    '& .heart': {
      color: theme.palette.primary.main,
      marginRight: 10
    }
  },
  social: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1.1px solid #bdbdbd52',
    padding: '0px 20px',
    paddingTop: 6,
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      marginBottom: 4,
      width: 40,
      height: 40,
      background: '#d3d3d338',
      color: '#000',
      fontWeight: 500
    },
    '& .names': {
      cursor: 'pointer',
      fontSize: 14,
      marginBottom: 6,
      marginTop: 4
    },
    '& .arrows': {
      fontSize: 14,
      marginBottom: -2
    }
  },
  closeshare: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .heart': {
      color: theme.palette.primary.main,
      marginRight: 10
    },
    '& .names': {
      height: '60px',
      borderTop: '1.1px solid #bdbdbd52',
      borderRight: '1.1px solid #bdbdbd52',
      borderBottom: '1.1px solid #bdbdbd52',
      width: '100%',
      padding: 10,
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      fontSize: 16,
      borderLeft: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '-4px'
    },
    '& .namesleft': {
      height: '60px',
      borderRight: 'none'
    },
    height: '60px'
  },
  pd: {
    padding: 20,
    '& .expired': {
      padding: '4px 14px',
      background: '#FF00002E',
      color: '#FF0000',
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    },
    '& .dis': {
      fontSize: 16,
      fontWeight: 600,
      marginTop: 6
    },
    '& .reopen': {
      marginBottom: -2,
      fontSize: 16
    }
  },
  call: {
    marginBottom: 8,
    // display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    '& .calbtn': {
      padding: '4px 10px',
      cursor: 'pointer',
      borderRadius: 8,
      '& .callicon': {
        fontSize: 16,
        marginRight: 6
      },
      textTransform: 'capitalize'
    }
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none !important',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  btn: {
    width: '100%',
    justifyContent: 'space-between',
    height: 70,
    marginBottom: '3%',
    fontSize: 18,
    textTransform: 'none',
    color: '#fff',
    boxShadow: 'none',
    borderRadius: 20
  },
  iconsimage: {
    '& img': {
      margin: 'auto'
    }
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'}) !important`,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important'
  },
  backimage2: {
    backgroundImage: `url(${'"/images/Mask Group 6.png"'}) !important`,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important'
  },
  backimage3: {
    backgroundImage: `url(${'"/images/Mask Group 9.png"'}) !important`,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important'
  },
  locationdistance: {
    background: '#EEEEEEB0',
    color: '#FF00A8',
    display: 'flex',
    padding: '2px 4px',
    borderRadius: 6,
    marginBottom: 20,
    fontSize: 13,
    alignItems: 'center',
    '& .kms': {
      // fontWeight: 600,
      fontStyle: 'italic',
      textDecoration: 'underline'
    }
  }
}))

function Feedcard (props) {
  const { list, dontredirect } = props
  const classes = useStyles()
  const [state, setState] = React.useState({ rtype: [] })
  const [reOpen, setReOpen] = React.useState(false)
  React.useEffect(() => {
    props.client
      .query({
        query: getAllResponseType,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const dates = {
          0:
            props.list.lastVerified && props.list.lastVerified.data.length > 0
              ? props.list.lastVerified.data[0].updatedAt
              : null,
          1:
            props.list.lastHelp && props.list.lastHelp.data.length > 0
              ? props.list.lastHelp.data[0].updatedAt
              : null,
          2:
            props.list.lastClose && props.list.lastClose.data.length > 0
              ? props.list.lastClose.data[0].updatedAt
              : null
        }
        const data = res.data?.allMasterResponseTypes?.nodes?.map((v, i) => {
          if (props?.list?.lastVerified?.data?.length > 0) {
            v.date = dates[i]
          } else if (props?.list?.lastHelp?.data?.length > 0) {
            v.date = dates[i]
          } else if (props?.list?.lastClose?.data?.length > 0) {
            v.date = dates[i]
          }
          return v
        })
        setState({ ...state, rtype: data })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setShareUrl = data => {
    let url =
      window.location.origin +
      '/' +
      GiveMeShareURL(
        RequestData(
          props.feedView ? JSON.stringify(data?.request) : data?.request
        ),
        props.feedView
          ? list.master_request_type
          : data?.masterRequestTypeByRequestType?.requestType,
        data?.id
      )
    return url
  }
  const reOpenRequest = async () => {
    const { list } = props
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: 'Sending OTP to ' + list.mobile_no
    })
    setReOpen(false)

    let url = Config.api_url + 'notification/otp/send',
      method = 'post',
      body = { mobile: list.mobile_no }
    await NetworkCall(url, method, body)
      .then(res => {
        if (res?.data && res?.data?.type === 'Success') {
          props.history.push({
            pathname: Routes.reOpenVerify,
            state: { reqId: list.id, mobileNo: list.mobile_no }
          })
        }
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: false,
          message: 'Sending OTP to ' + list.mobile_no
        })
      })
      .catch(e => {
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: false,
          message: 'Sending OTP to ' + list.mobile_no
        })
      })
  }
  // eslint-disable-next-line
  const phonecall = props.list?.nameList?.filter(v => {
    if (v?.phone) {
      return v?.name
    }
  })
  return (
    <>
      <Card className={classes.card} elevation={4}>
        <div className={classes.pd}>
          {props.subUser &&
            props.list.created_by !== localStorage.getItem('userId') && (
              <>
                {props.list?.distance > -1 && (
                  <div className={classes.locationdistance}>
                    <img
                      style={{ width: '42px' }}
                      src='./images/Location Anime Icon.gif'
                      alt='location'
                    />
                    <div>
                      You are{' '}
                      <span className={'kms'}>
                        {props?.list?.distance}{' '}
                        {props.list.distance === 1 ? 'km' : 'kms'}
                      </span>{' '}
                      away from {props.list?.titleName}.
                    </div>
                  </div>
                )}
              </>
            )}
          {!props.subUser &&
            (!dontredirect || (dontredirect && props.list?.status === 1)) && (
              <>
                {props.list?.distance > -1 && (
                  <div className={classes.locationdistance}>
                    <img
                      style={{ width: '42px' }}
                      src='./images/Location Anime Icon.gif'
                      alt='location'
                    />
                    <div>
                      You are{' '}
                      <span className={'kms'}>
                        {props?.list?.distance}{' '}
                        {props.list.distance === 1 ? 'km' : 'kms'}
                      </span>{' '}
                      away from {props.list?.title?.split(' ')[0]}.
                    </div>
                  </div>
                )}
              </>
            )}
          <div className={classes.emergencymain}>
            <Typography variant='caption' className='date'>
              Posted on {moment(props.list?.date).format('LL')}
              <br />
              {moment(props.list?.dates).fromNow()}
            </Typography>
            {list.isemergency && (
              <Button className='emergency' variant='contained'>
                Emergency
              </Button>
            )}
          </div>
          <Typography variant='h6' className={classes.username}>
            {props.list?.title}
          </Typography>
          {(!dontredirect || (dontredirect && props.list?.status === 1)) && (
            <div className={classes.call}>
              <span>
                {props.list?.nameList?.map(_n => {
                  return !_n.name ? (
                    <div></div>
                  ) : (
                    <Typography variant='caption' className={classes.lists}>
                      <div
                        style={{
                          height: 'auto',
                          objectFit: 'none',
                          display: 'flex'
                        }}
                        className={classes.iconsimage}
                      >
                        {_n?.name && (
                          <img
                            style={{ width: 16, height: 16 }}
                            src={_n?.img}
                            alt={_n?.img}
                          />
                        )}
                        &nbsp;&nbsp;{' '}
                        <span>
                          {_n.nameMap
                            ? _n.name.split(',')[1]
                              ? _n.name.split(',').map(c => {
                                  return (
                                    <div
                                      style={{
                                        borderBottom: '1px solid #eaeaea63',
                                        padding: '5px 0px',
                                        wordBreak:"break-word"
                                      }}
                                    >
                                      {c}
                                    </div>
                                  )
                                })
                              : _n?.name
                            : _n?.name}
                        </span>
                      </div>
                      {_n?.phone && (
                        <a
                          href={'tel:' + phonecall[0]?.name}
                          className={classes.phoneCall}
                          style={{
                            position: 'absolute',
                            right: 16,
                            marginTop: -8
                          }}
                        >
                          <Button
                            className='calbtn'
                            variant='outlined'
                            color='primary'
                          >
                            <CallIcon className='callicon' /> Call
                          </Button>
                        </a>
                      )}
                    </Typography>
                  )
                })}
              </span>
            </div>
          )}
        </div>

        <div className={classes.social}>
          <Typography variant='caption' className='names'>
            <Avatar>
              {props.list.veriyCount
                ? list.veriyCount
                : list.verifiedCount
                ? list.verifiedCount.totalCount
                : 0}
            </Avatar>
            Verified
          </Typography>
          <Typography
            variant='caption'
            className='names'
            onClick={() => {
              // if (!dontredirect) {
              props.history.push({
                pathname: Routes.comments,
                state: { requestId: list?.id }
              })
              localStorage.setItem('reqId', list?.id)
              // }
            }}
          >
            <Avatar>
              {props.list.commentCount
                ? list.commentCount
                : list.commentsByRequestId
                ? list.commentsByRequestId.totalCount
                : 0}
            </Avatar>
            Comments{' '}
            {!dontredirect && <ArrowForwardIosIcon className='arrows' />}
          </Typography>
          <Typography
            variant='caption'
            className='names'
            onClick={() => {
              if (!dontredirect || props.showcardslist) {
                props.history.push({
                  pathname: Routes.willinghelp,
                  state: { requestId: list?.id }
                })
              }
            }}
          >
            <Avatar>
              {list.HelpCount
                ? list.HelpCount
                : list.canHelpCount
                ? list.canHelpCount.totalCount
                : 0}
            </Avatar>
            Can Help{' '}
            {!dontredirect && <ArrowForwardIosIcon className='arrows' />}
          </Typography>
        </div>
        {props.list?.status !== 2 &&
          props.list?.status !== 3 &&
          (!props.dontShowShare || dontredirect) && (
            <div className={classes.closeshare}>
              {dontredirect ? (
                <Typography
                  variant='caption'
                  className='names'
                  onClick={() => {
                    props.history.push({
                      pathname: Routes.comments,
                      state: { requestId: list?.id }
                    })
                    localStorage.setItem('reqId', list?.id)
                  }}
                >
                  <img
                    alt='img'
                    src='/images/conversation.svg'
                    style={{ width: '18px' }}
                  />
                  &nbsp; Comment
                </Typography>
              ) : (
                <Typography
                  variant='caption'
                  className='names'
                  onClick={() => props.handleopemodle(list?.id)}
                >
                  {/* <CloseIcon className='heart' />  */}
                  Close
                </Typography>
              )}

              {/* {JSON.stringify(list.title)}  */}
              <RWebShare
                data={{
                  text: list.title + '\n',
                  url: setShareUrl(list),
                  title: 'Share Post'
                }}
              >
                <Typography
                  variant='caption'
                  className='names namesleft'
                  // onClick={() => props.handleShare(list.id)}
                >
                  <img
                    alt='img'
                    src='/images/share-option.svg'
                    style={{ width: '18px' }}
                  />
                  &nbsp; Share
                </Typography>
              </RWebShare>
            </div>
          )}

        {props.list?.status === 2 && (
          <div className={classes.pd}>
            <div className={classes.emergencymain}>
              <Button className='closed' variant='contained' color='primary'>
                Closed
              </Button>
              <Typography variant='caption' className='closeddate'>
                {/* {props.list?.masterRequestCloseReasonByCloseReason?.reason} */}
              </Typography>
            </div>
            {list?.specialNote && (
              <Card
                className={classes.closed}
                elevation={4}
                style={{ boxShadow: 'unset !important' }}
              >
                <Typography
                  variant='subtitle2'
                  className='title'
                  style={{ fontSize: 13 }}
                >
                  Special Note
                </Typography>
                <Typography className='dis'>{list?.specialNote}</Typography>
              </Card>
            )}
          </div>
        )}
        {props.list?.status === 3 && (
          <div className={classes.pd}>
            <Button className='expired' variant='contained'>
              Expired
            </Button>
            {!props.dontShowShare && (
              <Typography
                onClick={() => setReOpen(true)}
                className='dis'
                style={{ cursor: 'pointer' }}
              >
                Click to reopen request
                <ArrowForwardIosIcon className='reopen' />
              </Typography>
            )}
            <DialogComponent
              title={'Are you sure, do you want reopen this request'}
              open={reOpen}
              // body={this.dialogComponentBody(classes)}
              onOk={() => reOpenRequest()}
              onCancel={() => setReOpen(false)}
              positiveBtn='Reopen Request'
              negativeBtn='Cancel'
            />
          </div>
        )}
        {!props.showcardslist && (
          <>
            {props.dontShowShare &&
              !props.list?.closeReason &&
              props.list?.status === 1 && (
                <div style={{ padding: 20 }}>
                  {state.rtype.map((val, i) => (
                    <Button
                      variant='contained'
                      style={{
                        background:
                          (i === 0 && '#675bff') ||
                          (i === 1 && '#FFA33A ') ||
                          (i === 2 && '#d64949')
                      }}
                      className={
                        classes.btn +
                        ' ' +
                        `${
                          i === 0
                            ? classes.backimage1
                            : i === 1
                            ? classes.backimage2
                            : classes.backimage3
                        }`
                      }
                      onClick={() =>
                        props.history.push({
                          pathname: Routes.requestVerificationParent + val.form,
                          state: {
                            request_id: list?.id,
                            response_type: val.id,
                            rTypeId: val.id,
                            detail: val.response,
                            reqID: window.location?.pathname?.split('_')[1],
                            copyLinkURl:
                              window.location.origin +
                              '/' +
                              GiveMeShareURL(
                                RequestData(
                                  props.feedView
                                    ? JSON.stringify(list?.request)
                                    : list?.request
                                ),
                                props.feedView
                                  ? list.master_request_type
                                  : list?.masterRequestTypeByRequestType
                                      ?.requestType,
                                list?.id
                              )
                          }
                        })
                      }
                      endIcon={
                        <ChevronRightIcon
                          style={{
                            background: ' #ffff',
                            color: 'red',
                            borderRadius: ' 50%'
                          }}
                        />
                      }
                    >
                      <div>
                        <div className={classes.cardsverifydatestitle}>
                          {val.response}
                        </div>
                        <span className={classes.cardsverifydates}>
                          {val?.date && (
                            <>
                              {i === 0 && 'Last verified '}
                              {i === 1 && 'Last pledged to help '}
                              {i === 2 && 'Last reported '}
                              {moment(val?.date).fromNow()}
                            </>
                          )}
                        </span>
                      </div>
                    </Button>
                  ))}
                </div>
              )}
          </>
        )}
      </Card>
    </>
  )
}

export default withRouter(withApollo(withAllContexts(Feedcard)))
