/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-04-15
 * @desc Not Found component
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';

export const NotFoundSection = (props) => {

  const {
    message,
    top,
    showActionBtn = false,
    actionBtnName = 'Button',
    onActionButtonClicked = () => false
  } = props;

  return <div><Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    spacing={1}
    style={{ marginTop: top }}
  >
    <Grid item>
      <Typography variant="h6" align="center">
        {message}
      </Typography>
    </Grid>

    {showActionBtn && <Grid item>
      <Button variant="contained" color="primary" onClick={onActionButtonClicked}>
        {actionBtnName}
      </Button>
    </Grid>}

  </Grid>
  </div>
}

NotFoundSection.propTypes = {
  message: PropTypes.string,
  top: PropTypes.string,
  actionBtnName: PropTypes.string,
  showActionBtn: PropTypes.bool,
  onActionButtonClicked: PropTypes.func
}

NotFoundSection.defaultProps = {
  message: '404 - Not Found',
  top: '30vh'
}

