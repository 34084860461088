import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SearchLocationInput from './search'

// const useStyles = makeStyles((theme) => ({
  
// }));

const LocationParent = (props) => {
  // const classes = useStyles();
  
    
  return (
    <div>
        <SearchLocationInput
            value={props?.value?.address}
            label={props?.label}
            handleChange={props?.handleChange}
            isReadonly={props?.isReadonly ?? false}
            isrequired={props?.isrequired ?? false}
        />
    </div>
  );
};
LocationParent.propTypes = {
  value:PropTypes.string,
  label:PropTypes.string,
  handleChange:PropTypes.func,
};

export default LocationParent;
