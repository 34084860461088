import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import OtpVerifyComonent from "../../components/otpComponent";
import { Routes } from "../../router/routes";
import { withAllContexts } from "../../HOCs";
import { withApollo } from "react-apollo";
import { ReopenRequest } from "../../graphql/mutations";
const styles = (theme) => ({});
class ReopenVerify extends React.Component {
    constructor(props) {
        super(props);
        this.state = { otp: "", name: "" };
    }

    handleChange = (otp) => this.setState({ otp });

    handleSuccess = async () => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: true, message: 'Reopening Your request...' });
        const { reqId } = this.props.location.state;
        
        await this.props.client
            .mutate({
                mutation: ReopenRequest,
                variables: {
                    reqId,
                    updatedAt: new Date(),
                }
            })
            .then(data => {
                this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false, message: '' });
                this.props.history.push({
                    pathname: Routes.requests
                })
            })
            .catch(error => {
                this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false, message: '' });
                console.log(error)
            })
    }

    render() {
        const { classes } = this.props;
        const { mobileNo } = this.props.location.state;
        const { otp } = this.state;
        return (
            <div className={classes.root}>
                <OtpVerifyComonent
                    value={otp}
                    mobileNo={mobileNo}
                    handleChange={this.handleChange}
                    successFun={this.handleSuccess}
                />
            </div>
        );
    }
}

export default withAllContexts(withRouter(withStyles(styles)(withApollo(ReopenVerify))));