import React from 'react'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import {
  Typography,
  Grid,
  withStyles,
  Card,
  CardHeader,
  TextField
} from '@material-ui/core'
import { Header } from '../../components'
import { RequestData } from '../../funcations'
import {
  Feedcard
  // , LoadingSection
} from '../../components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withApollo } from 'react-apollo'
import {
  getAllRequest,
  getAllMasterCloseReason,
  getProfile
} from '../../graphql/queries'
import { closeMyRequest } from '../../graphql/mutations'
import { DialogComponent } from '../../components/dialog/index'
import moment from 'moment'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  subtitle: {
    // marginBottom: '-16px',
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 8
  },
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '20px 0px 0px',
    borderRadius: 20
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 80,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    paddingTop: 15,
    cursor: 'pointer'
  },
  closecardDescription: {
    cursor: 'pointer',
    marginBottom: 8,
    background: `${theme.palette.primary.main}`,
    padding: 0,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    '& .title': {
      fontSize: 14
    },
    '& .head': {
      padding: '14px 10px',
      marginTop: -6
    }
  },
  subtitles: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    fontSize: 13,
    '& .icon': {
      fontSize: 11,
      marginLeft: 6
    }
  },
  nodata: {
    marginTop: 15,
    textAlign: 'center'
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  textcard: {
    background: '#0000000d'
    // marginBottom: 10
  },
  nocomm: {
    color: '#000',
    height: '48vh',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 14
  }
})
class Requests extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      closedata: [],
      closeReason: null,
      whywontclose: false,
      sharenote: false,
      close: false,
      selectedReqId: null,
      name: null
    }
  }
  checktochatlist = res => {
    var phoneno = /^\d{10}$/
    if (res?.data?.userProfileById?.firstName?.match(phoneno)) {
      return ''
    } else {
      if (res?.data?.userProfileById?.firstName) {
        return res?.data?.userProfileById?.firstName
      } else {
        return ''
      }
    }
  }
  getPrifielDetails = () => {
    this.props.client
      .query({
        query: getProfile,
        variables: { id: localStorage.userId },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        if (res?.data?.userProfileById) {
          this.setState({
            name: this.checktochatlist(res)
          })
        }
      })
  }
  componentDidMount () {
    if (!localStorage.userId) {
      return false
    }
    this.getPrifielDetails()
    this.props.client
      .query({
        query: getAllMasterCloseReason,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setState({
          closedata: res.data.allMasterRequestCloseReasons.nodes
        })
      })

    this.fetchAllData()
  }
  boldgrp = json => {
    if (json.units) {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ', ' : '') +
          json.units +
          ' Units',
        img: '/images/blood.svg'
      }
    } else {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ' ' : '') +
          (json.units ? json.units : ''),
        img: '/images/blood.svg'
      }
    }
  }
  fetchAllData = () => {
    this.props.client
      .query({
        query: getAllRequest,
        variables: {
          userId: localStorage.userId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []
        res.data.allRequests.nodes.map((val, i) => {
          if (val.isActive) {
            let json = RequestData(val.request)
            let mentionarr = [
              this.boldgrp(json),
              { name: json.mention, img: '/images/patient.svg', nameMap: true },
              {
                name: json.medicine ? json.medicine : null,
                img: '/images/medicine.svg'
              },
              {
                name: json.needby
                  ? 'Needed by ' + moment(json.needby).format('LL')
                  : '',
                img: '/images/Needed.svg'
              },
              {
                name:
                  (json.age ? json.age + ', ' : '') +
                  (json.gender ? json.gender : ''),
                img: '/images/calendar (1).svg'
              },
              {
                name: json.cantactnumber
                  ? json.cantactnumber
                  : json.mno
                  ? json.mno
                  : '',
                img: '/images/cell-phone.svg',
                phone: true
              },
              { name: json.address, img: '/images/location.svg' }
            ]
            mentionarr = mentionarr.filter(
              val => val.name && val.name.length > 0
            )
            let cat = val?.masterRequestTypeByRequestType?.requestType
            if (cat && cat.toLocaleLowerCase().includes('others')) {
              cat = 'Help'
            }
            listData.push({
              updateddate: val.updatedAt,
              date: new Date(val.createdAt).toDateString(),
              dates: val.createdAt,
              title: json.name + ' needs ' + cat,
              titleName:json.name,
              nameList: mentionarr,
              isemergency: json.ems,
              mobile_no: json.mno,
              shareUrl: val.requestType,
              ...val
            })
            return val
          }
        })
        this.setState({
          list: listData
        })
      })
      .catch(e => {
        alert('something went wrong!')
      })
  }

  handleShare = id => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Post',
          text: `Check out our latest request`,
          url: Routes.sharedRequestuse + id
        })
        .then(() => {
          console.log('Successfully shared')
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error)
        })
    }
  }

  handlechoosewhyclose = (_list, i) => {
    const state = this.state
    if (i === 2) {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: false,
        whywontclose: false,
        close: !state.close
      })
    } else {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: !state.sharenote,
        whywontclose: false
      })
    }
  }

  dialogComponentBody = classes => {
    const state = this.state
    if (state.whywontclose) {
      return (
        <div>
          {state.closedata?.map((_list, i) => {
            return (
              <Card
                className={classes.closecardDescription}
                elevation={4}
                onClick={() => this.handlechoosewhyclose(_list, i)}
              >
                <CardHeader
                  className='head'
                  title={<span className='title'>{_list?.name}</span>}
                />
              </Card>
            )
          })}
        </div>
      )
    } else if (state.sharenote) {
      return (
        <div>
          <TextField
            className={classes.textcard}
            type='text'
            fullWidth
            variant='outlined'
            placeholder='Type here...'
            onChange={e => this.setState({ specialNote: e.target.value })}
          />
          <br />
        </div>
      )
    }
  }

  handleopemodle = reqId => {
    const state = this.state
    this.setState({
      selectedReqId: reqId,
      whywontclose: !state.whywontclose
    })
  }

  handleonCancel = () => {
    const state = this.state
    this.setState({
      ...state,
      whywontclose: false,
      sharenote: false,
      close: false
    })
  }

  handleoky = async () => {
    const state = this.state

    await this.props.client
      .mutate({
        mutation: closeMyRequest,
        variables: {
          id: state.selectedReqId,
          closeReason: state?.closeReason?.id,
          updatedBy: localStorage.userId,
          specialNote: state?.specialNote,
          updatedAt: new Date()
        }
      })
      .then(data => {
        this.setState(
          {
            ...state,
            whywontclose: false,
            sharenote: false,
            close: false
          },
          () => {
            this.fetchAllData()
          }
        )
      })
      .catch(error => {
        console.log(error)
      })
    if (!state.whywontclose && state.sharenote) {
      this.setState({
        ...state,
        whywontclose: false,
        sharenote: false,
        close: false
      })
    }
  }

  render () {
    const { classes } = this.props
    const { list, whywontclose, sharenote, close, name } = this.state
    return (
      <div className={classes.root}>
        <DialogComponent
          negativeBtnDontShow={whywontclose ? true : false}
          positiveBtnDontShow={whywontclose ? true : false}
          title={
            (whywontclose && 'Why do you want to close?') ||
            (sharenote && 'Share your thank you note (Optional)') ||
            (close && 'Are you sure, would you like to colse the request?')
          }
          open={whywontclose || sharenote || close}
          body={this.dialogComponentBody(classes)}
          onOk={this.handleoky}
          onCancel={this.handleonCancel}
          positiveBtn='Close Request'
          negativeBtn='Cancel'
        />
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header
              showAvatar={true}
              avatarText={name ? name[0] : ''}
              showNotificationIcon={true}
              discription={true}
            />
            <Grid className={classes.body}>
              <Card
                onClick={() => this.props.history.push(Routes.registrationType)}
                className={classes.cardDescription + ' ' + classes.backimage1}
                elevation={4}
              >
                <span style={{ fontWeight: 600, fontSize: 18 }}>
                  Do you need help?
                </span>
                <span className={classes.subtitles}>
                  Post and share request
                  <ArrowForwardIosIcon fontSize='small' className='icon' />
                </span>
              </Card>

              <Typography variant='h6' className={classes.subtitle}>
                {list.length !== 0 && 'My Requests'}
              </Typography>
              {/* {list.length === 0 && <LoadingSection />} */}
              {list.length === 0 && (
                <div className={classes.nocomm}>
                  <div>
                    <img
                      src='https://img.icons8.com/clouds/100/000000/send-letter-1.png'
                      alt='loading...'
                      style={{
                        width: 120,
                        height: 120,
                        marginBottom: -20
                      }}
                    />
                    <br />
                    <label style={{ fontSize: 13 }}>No requests yet.</label>
                  </div>
                </div>
              )}
              <div
                style={{
                  marginBottom: '80px'
                }}
              >
                {list?.map(_list => {
                  return (
                    <Feedcard
                      list={_list}
                      handleShare={this.handleShare}
                      handleopemodle={this.handleopemodle}
                      history={this.props.history}
                    />
                  )
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(Requests)))
