import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  withStyles,
  Grid,
  Typography,
  OutlinedInput,
  CardHeader,
  Avatar,
  InputAdornment,
  Card
} from '@material-ui/core'
import {
  Header
  // LoadingSection
} from '../../components'
import SendIcon from '@material-ui/icons/Send'
import { allCommends } from '../../graphql/queries'
import { createCommend } from '../../graphql/mutations'
import { withApollo } from 'react-apollo'
import { v4 as uuidv4 } from 'uuid'
import { withAllContexts } from '../../HOCs'
import { AlertProps } from '../../utils'
var Filter = require('bad-words')
var customFilter = new Filter({ placeHolder: '$!$' })

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh'
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'hidden',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  back: {
    marginBottom: -6,
    cursor: 'pointer'
  },
  title: {
    fontSize: 13,
    fontWeight: 600
  },
  dis: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  msg: {
    fontWeight: 500,
    fontSize: 13,
    marginTop: 4
  },
  cardheader: {
    borderBottom: '1.1px solid #bdbdbd2e',
    '& .MuiAvatar-root': {
      marginTop: -20,
      width: 34,
      height: 34,
      fontSize: 13
    },
    padding: '0px',
    paddingBottom: '8px',
    paddingTop: '8px'
  },

  chatlist: {
    height: '60vh',
    overflow: 'auto'
  },
  body: {
    padding: '10px 20px 0px'
  },
  titlecomments: {
    display:"flex",
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: 20,
    paddingTop: 20,
    '& div': {
      width: 20,
      height: 20,
      background: theme.palette.secondary.main,
      color: '#fff',
      borderRadius: '50%',
      fontSize: 12,
      marginTop: 2,
      marginLeft: 4,
      paddingLeft: 6,
      paddingTop: 2
    }
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    padding: '0px 10px'
  },
  sendmsg: {
    background: '#fff',
    height: '43px',
    borderRadius: '50%',
    width: '43px',
    display: 'flex',
    '& svg': {
      marginLeft: 10,
      fontSize: 16
    }
  },
  bottombox: {
    height: 76,
    position: 'relative',
    borderTop: '1.1px solid #bdbdbd2e'
  },
  textbox: {
    '& .send': {
      cursor: 'pointer'
    },
    borderRadius: 40,
    background: '#d3d3d357',
    height: '46px',
    bottom: 14,
    position: 'absolute'
  },
  nocomm: {
    color: '#000',
    height: '60vh',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 14
  }
})
class CommentsBox extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      chat: null
    }
  }
  componentDidMount () {
    this.fetchAllData()
  }
  checktochatlist = val => {
    var phoneno = /^\d{10}$/
    if (val?.userProfileByCreatedBy?.firstName?.match(phoneno)) {
      return 'Anonymous'
    } else {
      if (val?.userProfileByCreatedBy?.firstName) {
        return val?.userProfileByCreatedBy?.firstName
      } else {
        return 'Anonymous'
      }
    }
  }
  fetchAllData = () => {
    let { requestId } = this.props?.location?.state
    // let reqId = localStorage.reqId
    // requestId = requestId ? requestId : reqId;
    if (!requestId) {
      return false
    }
    this.props.client
      .query({
        query: allCommends,
        variables: {
          requestId: requestId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []
        res.data.allComments.nodes.map(val => {
          listData.push({
            name: this.checktochatlist(val),
            date: new Date(val.createdAt).toDateString(),
            msg: val.comment,
            img: ''
          })
          return val
        })
        this.setState({
          list: listData
        })
      })
      .catch(e => {
        alert('something went wrong!')
      })
  }
  handlechangechar = v => {
    const state = this.state
    this.setState({ ...state, chat: v })
  }
  handleenter = e => {
    if (e.key === 'Enter') {
      this.saveChat()
    }
  }
  saveChat = async () => {
    let { requestId } = this.props?.location?.state
    let userId = localStorage.userId
    if (customFilter.clean(this.state.chat).includes('$!$')) {
      this.props.alert.setSnack({
        ...this.props.alert.alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Please remove unwanted words.',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    } else {
      await this.props.client
        .mutate({
          mutation: createCommend,
          variables: {
            id: uuidv4(),
            comment: this.state.chat,
            createdBy: userId,
            createdAt: new Date(),
            requestId: requestId,
            updatedAt: new Date(),
            updatedBy: userId
          }
        })
        .then(data => {
          this.setState(
            {
              chat: ''
            },
            () => {
              this.fetchAllData()
            }
          )
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  handleaddchar = () => {
    this.saveChat()
  }
  render () {
    const { classes } = this.props
    const { list } = this.state
    const backgroundColorCode = [
      '#5449d696',
      '#ff00a882',
      '#f44336ad',
      '#6ef43673',
      '#f1f43673'
    ]
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showBackIcon />
            <Typography variant='h5' className={classes.titlecomments}>
              Comments <div>{list?.length ?? 0}</div>
            </Typography>
            <Grid className={classes.body}>
              {/* {list.length === 0 && <LoadingSection />} */}
              {/* <div className={classes.nocomm}>No comments yet.</div> */}
              <Card className={classes.cardDescription} elevation={4}>
                <div className={classes.chatlist}>
                  {list.length > 0 ? (
                    <>
                      {list?.map((_msg, i) => {
                        return (
                          <CardHeader
                            className={classes.cardheader}
                            avatar={
                              <Avatar
                                src={_msg?.img}
                                style={{ background: backgroundColorCode[i] }}
                              >
                                {_msg?.name ? _msg?.name[0] : ''}
                              </Avatar>
                            }
                            title={
                              <Typography
                                variant='h6'
                                color='textPrimary'
                                className={classes.title}
                              >
                                {_msg?.name}
                              </Typography>
                            }
                            subheader={
                              <>
                                <Typography
                                  variant='h5'
                                  color='textPrimary'
                                  className={classes.dis}
                                >
                                  {_msg?.date}
                                </Typography>
                                <Typography
                                  variant='h5'
                                  color='textPrimary'
                                  className={classes.msg}
                                >
                                  {_msg?.msg}
                                </Typography>
                              </>
                            }
                          />
                        )
                      })}
                    </>
                  ) : (
                    <div className={classes.nocomm}>No comments yet.</div>
                  )}
                </div>
                <div className={classes.bottombox}>
                  <OutlinedInput
                    className={classes.textbox}
                    fullWidth={true}
                    value={this.state.chat}
                    onChange={e => this.handlechangechar(e.target.value)}
                    onKeyDown={e => this.handleenter(e)}
                    placeholder='Add comment'
                    endAdornment={
                      <InputAdornment
                        position='end'
                        className={classes.sendmsg}
                        onClick={() => this.handleaddchar()}
                      >
                        <SendIcon className='send' color='primary' />
                      </InputAdornment>
                    }
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles)(withApollo(withAllContexts(CommentsBox)))
)
