import gql from 'graphql-tag';

const getAllRequest = gql `query($userId: UUID){
  allRequests (orderBy : UPDATED_AT_DESC, condition:{createdBy : $userId}){
    nodes {
      lastVerified: requestResponsesByRequestId(
        condition: {responseType: 1}
        orderBy: UPDATED_AT_DESC
        first: 1 
      ) {
        data: nodes {
          updatedAt
        }
      }
      lastHelp: requestResponsesByRequestId(
        condition: {responseType: 2}
        orderBy: UPDATED_AT_DESC
        first: 1
      ) {
        data: nodes {
          updatedAt
        }
      }
      lastClose: requestResponsesByRequestId(
        condition: {responseType: 3}
        orderBy: UPDATED_AT_DESC
        first: 1
      ) {
        data: nodes {
          updatedAt
        }
      }
      id
      requestType
      masterRequestTypeByRequestType{
        requestType
      }
      formId
      createdAt
      createdBy
      isActive
      request
      requestLink
      requestedBy
      specialNote
      status
      updatedAt
      updatedBy
      viewCount
      commentCount
      commentsByRequestId{
        totalCount
      }
      masterRequestStatusByStatus{
        status
      }
      closeReason
      masterRequestCloseReasonByCloseReason{
        reason
      }
      verifiedCount:requestResponsesByRequestId(condition: {responseType: 1}) {
        totalCount
      }
      canHelpCount:requestResponsesByRequestId(condition: {responseType: 2}) {
        totalCount
      }  
    }
  }
}
  `

const getRequestDetails = gql `query($reqId: UUID) {
  allRequests(condition: { id: $reqId }) {
    nodes {
      lastVerified: requestResponsesByRequestId(
        condition: { responseType: 1 }
        orderBy: UPDATED_AT_DESC
        first: 1
      ) {
        data: nodes {
          updatedAt
        }
      }
      lastHelp: requestResponsesByRequestId(
        condition: { responseType: 2 }
        orderBy: UPDATED_AT_DESC
        first: 1
      ) {
        data: nodes {
          updatedAt
        }
      }
      lastClose: requestResponsesByRequestId(
        condition: { responseType: 3 }
        orderBy: UPDATED_AT_DESC
        first: 1
      ) {
        data: nodes {
          updatedAt
        }
      }
      id
      requestType
      masterRequestTypeByRequestType {
        requestType
      }
      formId
      createdAt
      createdBy
      isActive
      request
      requestLink
      requestedBy
      specialNote
      status
      updatedAt
      updatedBy
      viewCount
      commentCount
      commentsByRequestId {
        totalCount
      }
      masterRequestStatusByStatus {
        status
      }
      closeReason
      masterRequestCloseReasonByCloseReason {
        reason
      }
      verifiedCount: requestResponsesByRequestId(
        condition: { responseType: 1 }
      ) {
        totalCount
      }
      canHelpCount: requestResponsesByRequestId(
        condition: { responseType: 2 }
      ) {
        totalCount
      }
    }
  }
}
`


const allCommends = gql `query($requestId : UUID){
  allComments(orderBy : CREATED_AT_DESC,condition :{ requestId : $requestId}){
    nodes{
      comment
      id
      isActive
      createdAt
      commentStatus
      userProfileByCreatedBy{
        firstName
      }
    }
  }
}`

const getAllMasterCloseReason = gql `{
  allMasterRequestCloseReasons{
    nodes{
      id
      name:reason
    }
  }
}`

const getAllMasterFormTypes = gql `{
  allMasterRequestTypes{
    nodes{
      id
      imageUrl:image
      form
      othersForm
      label: requestType
      value:id
    }
  }
}`


const getAllResponseType = gql `{
  allMasterResponseTypes{
    nodes{
      id
      response
      form
    }
  }
}`

const getAllRequestResponse = gql `query($requestId :UUID){
  allRequestResponses( condition : {
    requestId : $requestId
    responseType : 2
  }){
    nodes{
      id
      requestId
      responseType
      formId
      response
      userProfileByCreatedBy{
        firstName
      }
      createdBy
    }
  }
}`

const getAllNotification = gql `query ($notificationTo: UUID) {
  allNotifications(
    orderBy: CREATED_AT_DESC
    condition: {notificationTo: $notificationTo}
  ) {
    nodes {
      id
      isActive
      requestId
      message
      notificationTo
      createdAt
      requestByRequestId {
        masterRequestTypeByRequestType {
          requestType
          isActive
        }
        request
        userProfileByCreatedBy {
          firstName
        }
      }
    }
  }
}
`
const getProfile = gql `query($id: UUID!) {
  userProfileById(id: $id) {
    firstName
    id
    requestsPerDay
    radius
    mobileNo
    locality
    subscripedCategories
  }
}
`;
export {
  getAllRequest,
  allCommends,
  getAllMasterCloseReason,
  getAllMasterFormTypes,
  getRequestDetails,
  getAllResponseType,
  getAllRequestResponse,
  getAllNotification,
  getProfile
}