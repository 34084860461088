/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-02-11
 * @desc Internal Server Error page
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid,Typography,Button } from '@material-ui/core';

export const InternatlServerError = (props) =>{

    const {
        message,
        onRetry,
        retryBtnName,
        top
    } = props;

    return <div><Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{ marginTop: top }}
  >
    <Grid item>
      <Typography variant="h6" align="center">
        {message}
      </Typography>
    </Grid>
    <Grid item>
      <Button
        style={{ marginTop: 16, textAlign: "center" }}
        variant={"outlined"}
        onClick={onRetry}
      >
        {retryBtnName}
      </Button>
    </Grid>
  </Grid>
  </div>
}

InternatlServerError.propTypes = {
    message: PropTypes.string,
    onRetry: PropTypes.func.isRequired,
    retryBtnName: PropTypes.string,
    top: PropTypes.string
}

InternatlServerError.defaultProps = {
    message: 'Something went wrong!',
    retryBtnName: 'Try Again',
    top: '30vh'
}

