import React from 'react'
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withAllContexts } from '../../HOCs'
import { Header } from '../../components'
import { getProfile } from '../../graphql/queries'
import { Updateprofile, UpdateprofileSubscriber } from '../../graphql/mutations'
import { withApollo } from 'react-apollo'
import { AlertProps } from '../../utils'
import { FormRenderer } from '..'
// import { RequestData } from '../../funcations'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  wrapper: {
    '& input': {
      background: '#fff'
    }
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20
  },
  buttonStyle: {
    height: '50px',
    borderRadius: 10,
    marginBottom: '80px'
  },
  label: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    marginTop: 20,
    marginBottom: 10
  }
})

class ProfileDetailsComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: null,
      locality: null,
      radius: null,
      num_request: null,
      contact_num: null,
      longitude: null,
      latitude: null,
      openview:false
    }
  }
  checktochatlist = res => {
    var phoneno = /^\d{10}$/
    if (res?.data?.userProfileById?.firstName?.match(phoneno)) {
      return ''
    } else {
      if (res?.data?.userProfileById?.firstName) {
        return res?.data?.userProfileById?.firstName
      } else {
        return ''
      }
    }
  }
  getPrifielDetails = async () => {
    await this.props.client
      .query({
        query: getProfile,
        variables: { id: localStorage.userId },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        if (res?.data?.userProfileById) {
          this.setState({
            name: this.checktochatlist(res),
            locality: res?.data?.userProfileById?.locality,
            longitude: res?.data?.userProfileById?.longitude,
            latitude: res?.data?.userProfileById?.latitude,
            radius: res?.data?.userProfileById?.radius,
            num_request: res?.data?.userProfileById?.requestsPerDay,
            contact_num: res?.data?.userProfileById?.mobileNo,
            openview:true
          })
        }
      })
  }
  componentDidMount=async()=> {
  await   this.getPrifielDetails()
  }

  onChange = (v, n) => {
    this.setState({
      [n]: v
    })
  }
  share =  v => {
    debugger
    var json
    if (localStorage.getItem('isSubscribed')) {
      json = Object.values(v?.answer)
    }
    // if (!this.state.name&&!json[0].answer) {
    //   this.props.alert.setSnack({
    //     ...this.props.alert.alert,
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: 'Please fill the mandatory fields!',
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center
    //   })
    //   return
    // }
    var body
    if (localStorage.getItem('isSubscribed')) {
      body = {
        id: localStorage.userId,
        firstName: !localStorage.getItem('isSubscribed')
          ? this.state.name
          : json[0].answer,
        requestsPerDay: json[3].answer ? JSON.parse(json[3].answer) : null,
        radius: JSON.parse(json[2].answer),
        // mobileNo: json[4].answer,
        locality: json[1].answer?.address,
        longitude: json[1].answer?.longitude,
        latitude: json[1].answer?.latitude
      }
    } else {
      body = {
        id: localStorage.userId,
        firstName: this.state.name
      }
    }
    this.props.client
      .mutate({
        mutation: !localStorage.getItem('isSubscribed')
          ? Updateprofile
          : UpdateprofileSubscriber,
        variables: {
          ...body
        }
      })
      .then(data => {
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: 'Great! Your profile is updated.',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  giveMeAnswer = bulkAnswers => {
    let answer = {}
    bulkAnswers?.answers?.map((ans, index) => {
      return (answer[ans?.question_id] = ans.answer)
    })
    return answer
  }
  onSubmitBtnClicked = data => {
    this.giveMeAnswer(data)
  }
  render () {
    const { classes } = this.props
    const {
      name,
      locality,
      radius,
      num_request,
      contact_num,
      longitude,
      latitude,
      openview
    } = this.state

    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography variant='h6' className={classes.title}>
                Please provide your details.
              </Typography>
              {localStorage.getItem('isSubscribed') ? (
                <>
                {openview&&
                  <FormRenderer
                    onSubmitBtnClicked={this.share}
                    submitBtnName={'Update'}
                    answer={{
                      '7738fb66-541d-45d8-b1e5-8a3b50f594e6': {
                        id: '7738fb66-541d-45d8-b1e5-8a3b50f594e6',
                        tag: 'Name',
                        answer: name?name:""
                      },
                      'b2ef2116-e729-4778-892b-6f8c8fd2179a': {
                        id: 'b2ef2116-e729-4778-892b-6f8c8fd2179a',
                        tag: 'Location',
                        answer: {
                          address: locality,
                          latitude: latitude,
                          longitude: longitude
                        }
                      },
                      'c50442d4-8498-47c5-b8e3-2ab5dccb5e34': {
                        id: 'c50442d4-8498-47c5-b8e3-2ab5dccb5e34',
                        tag: 'Radius',
                        answer: radius
                      },
                      'cf27ad0e-a9d2-472a-9959-63253a7a4922': {
                        id: 'cf27ad0e-a9d2-472a-9959-63253a7a4922',
                        tag: 'Requests Serve Limit',
                        answer: num_request
                      },
                      'bd4d10aa-4748-4287-8ee1-318f4c98371d': {
                        id: 'bd4d10aa-4748-4287-8ee1-318f4c98371d',
                        tag: 'Mobile Number',
                        answer: contact_num
                      }
                    }}
                    showCheckBox={false}
                    profile={true}
                    checkBoxText={false}
                  />}
                </>
              ) : (
                <>
                  <TextField
                    value={name}
                    onChange={e => this.onChange(e.target.value, 'name')}
                    fullWidth
                    label={
                      <span>
                        What's your name? 
                        <label style={{ color: 'red' }}>*</label>
                      </span>
                    }
                    variant='outlined'
                    className={classes.wrapper}
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />
                  <br />
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    className={classes.buttonStyle}
                    onClick={this.share}
                  >
                    {'Update'}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles)(withAllContexts(withApollo(ProfileDetailsComponent)))
)
