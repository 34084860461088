import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  withStyles,
  TextField,
  Card,
  CardHeader
} from '@material-ui/core'
import { NetworkCall } from '../../networkcall'
import {
  Header,
  InternatlServerError,
  LoadingSection,
  NotFoundSection
} from '../../components'
import CONFIG from '../../config'
import { DialogComponent } from '../../components/dialog/index'
import { distance, GiveMeShareURL, NetWorkCallMethods } from '../../utils'
import { Feedcard } from '../../components'
import { RequestData } from '../../funcations'
import { withApollo } from 'react-apollo'
import {
  getRequestDetails,
  getAllMasterCloseReason
} from '../../graphql/queries'
import { closeMyRequest } from '../../graphql/mutations'
import { Helmet } from 'react-helmet'
import moment from 'moment'
// import { Routes } from '../../router/routes'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    minHeight: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 14,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  body: {
    padding: 20,
    minHeight: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 80,
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    // marginLeft: 20,
    // marginRight: 20,
    marginTop: 16,
    marginBottom: 0,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    paddingTop: 15,
    cursor: 'pointer'
  },
  subtitles: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    fontSize: 13,
    '& .icon': {
      fontSize: 11,
      marginLeft: 6
    }
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '20px 0px 0px',
    borderRadius: 20
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  closecardDescription: {
    cursor: 'pointer',
    marginBottom: 8,
    background: `${theme.palette.primary.main}`,
    padding: 0,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    '& .title': {
      fontSize: 14
    },
    '& .head': {
      padding: '14px 10px',
      marginTop: -6
    }
  }
})

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}

function errors (err) {
  console.warn(`ERROR(${err.code}): ${err.message}`)
}
class ShareRequestDetails extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: null,
      loading: true,
      error: false,
      requestedBy: null,
      location: {},
      closedata: [],
      closeReason: null,
      whywontclose: false,
      sharenote: false,
      close: false,
      selectedReqId: null
    }
  }

  componentDidMount = () => {
    this.getCurrentLocation()
    this.getRequestDetail()
    this.props.client
      .query({
        query: getAllMasterCloseReason,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setState({
          closedata: res.data.allMasterRequestCloseReasons.nodes
        })
      })
  }

  getCurrentLocation = () => {
    const myThis = this
    if (navigator?.geolocation) {
      navigator?.permissions
        ?.query({ name: 'geolocation' })
        ?.then(function (result) {
          if (result.state === 'granted') {
            console.log(result.state)
            //If granted then you can directly call your function here
            navigator.geolocation?.getCurrentPosition(function (position) {
              let { list = {} } = myThis.state
              if (list) {
                list.distance = Math.round(
                  distance(
                    list?.requestLocation?.latitude,
                    list?.requestLocation?.longitude,
                    myThis.state?.location?.latitude,
                    myThis.state?.location?.longitude,
                    'K'
                  )
                )
                myThis.setState({ location: position.coords, list })
              } else {
                myThis.setState({ location: position.coords })
              }
            })
          } else if (result.state === 'prompt') {
            navigator.geolocation?.getCurrentPosition(
              function (position) {
                let { list = {} } = myThis.state
                if (list) {
                  list.distance = Math.round(
                    distance(
                      list?.requestLocation?.latitude,
                      list?.requestLocation?.longitude,
                      myThis.state?.location?.latitude,
                      myThis.state?.location?.longitude,
                      'K'
                    )
                  )
                  myThis.setState({ location: position.coords, list })
                } else {
                  myThis.setState({ location: position.coords })
                }
              },
              errors,
              options
            )
          } else if (result.state === 'denied') {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state)

            navigator.geolocation?.getCurrentPosition(
              function (position) {
                let { list = {} } = myThis.state
                if (list) {
                  list.distance = Math.round(
                    distance(
                      list?.requestLocation?.latitude,
                      list?.requestLocation?.longitude,
                      myThis.state?.location?.latitude,
                      myThis.state?.location?.longitude,
                      'K'
                    )
                  )
                  myThis.setState({ location: position.coords, list })
                } else {
                  myThis.setState({ location: position.coords })
                }
              },
              errors,
              options
            )
          }
        })
    } else {
      console.log('Sorry Not available!')
    }
  }

  boldgrp = json => {
    if (json.units) {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ', ' : '') +
          json.units +
          ' Units',
        img: '/images/blood.svg'
      }
    } else {
      return {
        name:
          (json.bloodgrp ? json.bloodgrp + ' ' : '') +
          (json.bloodtype ? json.bloodtype + ' ' : '') +
          (json.units ? json.units : ''),
        img: '/images/blood.svg'
      }
    }
  }
  getRequestDetail = () => {
    let request_id = this.props.match?.params?.id?.split('_')?.[1] ?? ''

    let body = { request_id: request_id }

    NetworkCall(
      CONFIG.api_url + '/request/viewCount/update',
      NetWorkCallMethods.post,
      body
    )

    this.setState({
      loading: true,
      error: false
    })

    this.props.client
      .query({
        query: getRequestDetails,
        variables: {
          reqId: request_id
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []
        res.data.allRequests.nodes.map(val => {
          if (val.isActive) {
            let json = RequestData(val.request)

            let link = GiveMeShareURL(
              json,
              val?.masterRequestTypeByRequestType?.requestType,
              request_id
            )

            let mentionarr = [
              this.boldgrp(json),
              { name: json.mention, img: '/images/patient.svg', nameMap: true },
              {
                name: json.medicine ? json.medicine : null,
                img: '/images/medicine.svg'
              },
              {
                name: json.needby
                  ? 'Needed by ' + moment(json.needby).format('LL')
                  : '',
                img: '/images/Needed.svg'
              },
              {
                name:
                  (json.age ? json.age + ', ' : '') +
                  (json.gender ? json.gender : ''),
                img: '/images/calendar (1).svg'
              },
              {
                name: json.cantactnumber
                  ? json.cantactnumber
                  : json.mno
                  ? json.mno
                  : '',
                img: '/images/cell-phone.svg',
                phone: true
              },
              { name: json.address, img: '/images/location.svg' }
            ]
            mentionarr = mentionarr?.filter(
              val => val.name && val.name.length > 0
            )
            let cat = val?.masterRequestTypeByRequestType?.requestType
            if (cat && cat.toLocaleLowerCase().includes('others')) {
              cat = 'Help'
            }
            listData.push({
              updateddate: val.updatedAt,
              date: new Date(val.createdAt).toDateString(),
              dates: val.createdAt,
              title: json.name + ' needs ' + cat,
              titleName:json.name,
              nameList: mentionarr,
              isemergency: json.ems,
              shareUrl: val.requestType,
              link: link,
              mobile_no: json.mno,
              lastVerified: val?.lastVerified?.data,
              lastHelp: val?.lastHelp?.data,
              lastClose: val?.lastClose?.data,
              requestedBy: val?.requestedBy,
              distance: Math.round(
                distance(
                  json.location.latitude,
                  json.location.longitude,
                  this.state.location.latitude,
                  this.state.location.longitude,
                  'K'
                )
              ),
              requestLocation: json.location,
              ...val
            })

            return false
          }
        })
        this.setState(
          {
            list: listData[0],
            loading: false,
            error: false,
            requestedBy: listData[0]?.requestedBy
          },
          () => {
            this.props.history.push(`/${this.state.list?.link}`)
          }
        )
      })
      .catch(error => {
        console.log('error:', error)
        this.setState({
          list: {},
          loading: false,
          error: true
        })
      })
  }

  giveMeTitle = list => {
    let title = list?.title
    title = title?.toLocaleLowerCase()
    return title?.[0]?.toUpperCase() + title?.slice(1)
  }

  handleopemodle = reqId => {
    const state = this.state
    this.setState({
      selectedReqId: reqId,
      whywontclose: !state.whywontclose
    })
  }
  handleonCancel = () => {
    const state = this.state
    this.setState({
      ...state,
      whywontclose: false,
      sharenote: false,
      close: false
    })
  }

  handlechoosewhyclose = (_list, i) => {
    const state = this.state
    if (i === 2) {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: false,
        whywontclose: false,
        close: !state.close
      })
    } else {
      this.setState({
        ...state,
        closeReason: _list,
        sharenote: !state.sharenote,
        whywontclose: false
      })
    }
  }

  dialogComponentBody = classes => {
    const state = this.state
    if (state.whywontclose) {
      return (
        <div>
          {state.closedata?.map((_list, i) => {
            return (
              <Card
                className={classes.closecardDescription}
                elevation={4}
                onClick={() => this.handlechoosewhyclose(_list, i)}
              >
                <CardHeader
                  className='head'
                  title={<span className='title'>{_list?.name}</span>}
                />
              </Card>
            )
          })}
        </div>
      )
    } else if (state.sharenote) {
      return (
        <div>
          <TextField
            className={classes.textcard}
            type='text'
            fullWidth
            variant='outlined'
            placeholder='Type here...'
            onChange={e => this.setState({ specialNote: e.target.value })}
          />
          <br />
        </div>
      )
    }
  }
  handleoky = async () => {
    const state = this.state

    await this.props.client
      .mutate({
        mutation: closeMyRequest,
        variables: {
          id: state.selectedReqId,
          closeReason: state?.closeReason?.id,
          updatedBy: localStorage.userId,
          specialNote: state?.specialNote,
          updatedAt: new Date()
        }
      })
      .then(data => {
        this.setState(
          {
            ...state,
            whywontclose: false,
            sharenote: false,
            close: false
          },
          () => {
            this.getRequestDetail()
          }
        )
      })
      .catch(error => {
        console.log(error)
      })
    if (!state.whywontclose && state.sharenote) {
      this.setState({
        ...state,
        whywontclose: false,
        sharenote: false,
        close: false
      })
    }
  }
  render () {
    const { classes } = this.props
    const {
      sharenote,
      list,
      close,
      loading,
      error,
      requestedBy,
      whywontclose
    } = this.state
    return (
      <div className={classes.root}>
        <DialogComponent
          negativeBtnDontShow={whywontclose ? true : false}
          positiveBtnDontShow={whywontclose ? true : false}
          title={
            (whywontclose && 'Why do you want to close?') ||
            (sharenote && 'Share your thank you note (Optional)') ||
            (close && 'Are you sure, would you like to colse the request?')
          }
          open={whywontclose || sharenote || close}
          body={this.dialogComponentBody(classes)}
          onOk={this.handleoky}
          onCancel={this.handleonCancel}
          positiveBtn='Close Request'
          negativeBtn='Cancel'
        />
        <Helmet
          encodeSpecialCharacters={true}
          onChangeClientState={(newState, addedTags, removedTags) =>
            console.log(newState, addedTags, removedTags)
          }
        >
          <html lang='en' />
          <title>{this.giveMeTitle(list)}</title>
          <meta
            name='description'
            content={`${list?.isemergency ? 'Emergency! ' : ''} 
            ${list?.nameList?.[1] ?? ''} years ${list?.nameList?.[2] ??
              ''} from ${list?.nameList?.[4] ?? ''}`}
          />
          <link rel='canonical' href={window.location.href} />
          <meta property='og:type' content='website' />
          <meta property='og:site_name' content={'OneSoul'} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:title' content={this.giveMeTitle(list)} />
          <meta property='og:image' content='%PUBLIC_URL%/icons/192x192.png' />
        </Helmet>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header
              isNoHelmat={true}
              showAvatar={false}
              showCloseIcon={true}
              showNotificationIcon={false}
              discription={true}
            />
            <Grid className={classes.body}>
              {loading && !error && (
                <LoadingSection message='Fetching Request Details...' />
              )}

              {!loading && error && (
                <InternatlServerError
                  message='Something went wrong, Unable to get the request detail'
                  onRetry={this.getRequestDetail}
                />
              )}

              {!loading && !error && !list && (
                <NotFoundSection message='404 - Request not found' />
              )}
              {!loading && !error && list && (
                <React.Fragment>
                  {requestedBy === localStorage.getItem('userId') ? (
                    <Feedcard
                      list={list}
                      handleShare={this.handleShare}
                      handleopemodle={this.handleopemodle}
                      history={this.props.history}
                    />
                  ) : (
                    <Feedcard
                      list={list && list}
                      dontredirect
                      showcardslist={
                        requestedBy === localStorage.getItem('userId')
                          ? true
                          : false
                      }
                      dontShowShare={true}
                      handleShare={() => this.handleShare()}
                      handleopemodle={this.handleopemodle}
                      history={this.props.history}
                    />
                  )}
                </React.Fragment>
              )}

              {/* <Card
                onClick={() => this.props.history.push(Routes.registrationType)}
                className={classes.cardDescription + ' ' + classes.backimage1}
                elevation={4}
              >
                <span style={{ fontWeight: 600, fontSize: 18 }}>
                  Do you need help?
                </span>
                <span className={classes.subtitles}>
                  Post and share request
                  <ArrowForwardIosIcon fontSize='small' className='icon' />
                </span>
              </Card> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(ShareRequestDetails)))
