import React from 'react';
import { Routes } from '../router/routes';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewStreamIcon from '@material-ui/icons/ViewStream';


/**
 * Object with role as key and value, which is used for 
 * comparison of role in different place.
 */
export const UserRoles = {
    role: "role"
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
    "role": "Role"
};

/**
 * Object which has the different themes used in 
 * the application.
 */
export let Themes = {
    default: "default",
    dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert) 
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
    vertical: {
        top: "top",
        bottom: "bottom",
    },
    horizontal: {
        left: "left",
        right: "right",
        center: "center",
    },
    severity: {
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
    },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js) 
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

/**
 * Object has the key and value pair of all the keys which 
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
    authToken: "auth_token",
    version: "version"
};

export let localStorageKeys = {
    authToken: "auth_token",
    version: "version"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
    update: "UPDATE",
};


export const NavBarArr = [
    {
        name: "Forms",
        link: Routes.forms,
        icon: <ViewStreamIcon />
    }
]

export const FormComponents = {
    "single_tab_select": "single_tab_select",
    "multi_tab_select": "multi_tab_select",
    "input_text": "input_text",
    "input_textarea": "input_textarea",
    "input_date_picker": "input_date_picker",
    "multi_select_tag": "multi_select_tag",
    "custom_multi_select_tag": "custom_multi_select_tag",
    "select_modal_dropdown": "select_modal_dropdown",
    "list_text": "list_text",
    "input_number": "input_number",
    "hours_minutes_range": "hours_minutes_range",
    "grade": "grade",
    "image_picker": "image_picker",
    "switch_tab": "switch_tab",
    "range_picker": "range_picker",
    "popup_switch": "popup_switch",
    "label": "label",
    "note": "note",
    "check_box":"check_box",
    "radio_button":"radio_button",
    "location":'location',
    'custom_table':'custom_table', 
    'custom_table_2':'custom_table_2' 
}