import React from 'react'
import { Grid, withStyles, Typography, Card } from '@material-ui/core'
// import { localStorageKeys } from '../../utils';
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
//components
import { Header } from '../../components'
// icons
import KeyboardArrowRightIcon from '@material-ui/icons/ChevronRight'
import { setLocal, getLocal } from '../../funcations/common'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow:"0 0 1em 2px #00000040",
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0 
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  cardStyle: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: ''
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  imgStyle: {
    height: 300,
    width: '100%',
    objectFit: 'cover'
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
    color: '#fff',
    borderRadius: 20
  },
  listOfmenus: {
    marginBottom: theme.spacing(2),
    padding: 16,
    borderRadius: 20,
    cursor: 'pointer',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1.4px solid #fff',
    transition: '0.4s ease',
    '&:hover': {
      border: '1.4px solid #f9cded',
      transform: 'scale(1.03)'
    },
    height: '32vh',
    '& h5': {
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      fontSize:20,
      '& svg': {
        fontSize: '1.4em'
      }
    },

  },
  selected: {
    background: '#fad9f1',
    border: '1.4px solid #f9cded'
    // transform: 'scale(1.03)'
  },
  iconList: {
    border: '2px solid #f2f2f2',
    padding: '16px',
    borderRadius: 50,
    display: 'flex'
  },
  cardDescriptionImg: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    padding: 20,
    color: '#fff',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,fontSize:20
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 3.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  backimage2: {
    backgroundImage: `url(${'"/images/Mask Group 4.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
})
class RegistrationType extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeCard: null
    }
  }

  componentDidMount () {
    console.log('home')
    let data = getLocal('type')
    data = data ? data : {}
    this.setState({ ...data })
  }

  handleNavigation = index => {
    this.setState({
      activeCard: index
    })
    setLocal('type', { activeCard: index })
    this.props.history.push({
      pathname: Routes.needs,
      state: { selectedState: { ismyselef: index } }
    })
  }

  render () {
    const { classes } = this.props
    const { activeCard } = this.state
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header showCloseIcon />
            <div className={classes.body}>
              <Typography
                variant='h5'
                color={'textPrimary'}
                className={classes.marginBottom}
              >
                {'Seeking help for?'}
              </Typography>
              <Card
                className={
                  `${classes.listOfmenus} ${
                    activeCard === 0 ? classes.selected : ''
                  }` +
                  ' ' +
                  classes.backimage1
                }
                elevation={4}
                key={0}
                onClick={() => this.handleNavigation(0)}
                style={{ backgroundColor: '#675AFF' }}
              >
                <div>
                  <Typography variant={'h5'}>
                    Myself <KeyboardArrowRightIcon />
                  </Typography>
                </div>
              </Card>
              <Card
                className={
                  `${classes.listOfmenus} ${
                    activeCard === 1 ? classes.selected : ''
                  }` +
                  ' ' +
                  classes.backimage2
                }
                elevation={4}
                key={1}
                onClick={() => this.handleNavigation(1)}
                style={{ backgroundColor: '#FFA33A', marginBottom: '80px' }}
              >
                <div>
                  <Typography variant={'h5'}>
                    Others <KeyboardArrowRightIcon />
                  </Typography>
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(RegistrationType))
