import React from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { Header } from '../../components'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 100px)',
    overflow: 'hidden'
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20
  },
  iframe: {
    height: '96%',
    borderRadius: 10,
    overflow: 'hidden'
    //   background:"tr"
  }
})
class Privacypolicy extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {}

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header showCloseIcon />
            <div className={classes.body}>
              <Typography
                variant='h5'
                color={'textPrimary'}
                className={classes.marginBottom}
              >
                {'Privacy Policy'}
              </Typography>
              <div className={classes.iframe}>
                {/* jsx-a11y/iframe-has-title */}
                <iframe
                  title='Privacy Polic'
                  allowtransparency={true}
                  //   style={{ backgroundColor: 'red' }}
                  WIDTH='100%'
                  HEIGHT='100%'
                  //   SCROLLING='NO'
                  frameborder='0'
                  marginheight='0'
                  //   marginwidth='0'
                  hspace='0'
                  vspace='0'
                  src='https://docs.google.com/document/d/e/2PACX-1vSZ8I4V5QXUB5xZth474pbF1qZU7mtGiK1r16wG01FPD0ed-8fh_iwlcn1XPgBbB2mmyXta_icS8f6d/pub?embedded=true'
                ></iframe>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Privacypolicy))
