import React from 'react'
import { Grid, withStyles, Typography, Card, Link } from '@material-ui/core'
// import { localStorageKeys } from '../../utils';
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import { Header } from '../../components'
//components
// import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import Slider from 'react-slick'
import { localStorageKeys } from '../../utils'
const requestList = [
  {
    name: 'Do you need help?',
    subText: 'Post and share request',
    id: 1,
    styleClass: 'primarycard'
  },
  {
    name: 'Want to help?',
    subText: 'Register yourself and help',
    id: 2,
    styleClass: 'secondycard'
  }
]

const adList = [
  {
    name: 'OneSoul gets help faster.',
    dis1:
      'OneSoul is a platform through which you can find help ⁠— be it for oxygen, blood or life saving medicines.',
    dis2: 'Create and share your request to get help immediately.'
  },
  {
    name: 'Why are we here?',
    subName: 'We’re here to make sure',
    dis1: 'Requests NEVER get lost among social media posts.',
    dis2: 'You always know if help arrived in time.',
    dis3: 'Urgent requirements are given IMMEDIATE attention.',
    isbullet: true
  },
  {
    name: 'Need help?',
    dis1: 'Post your request and get your unique link.',
    dis2:
      'Start sharing your link. The more you share, the faster you will get help.',
    isbullet: true
  },
  {
    name: 'Want to help?',
    dis1: 'Fulfill the request directly, if you can.',
    dis2: 'Share the link on as many platforms as possible.',
    isbullet: true
  }
]
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  cardStyle: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: ''
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  imgStyle: {
    height: 300,
    width: '100%',
    objectFit: 'cover'
  },
  body: {
    // padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.secondary.main,
    padding: 20,
    color: '#fff',
    borderRadius: 20
  },
  listOfDes: {
    // marginBottom: theme.spacing(2),
    borderRadius: 20,
    cursor: 'pointer',
    // border: '1.4px solid #fff',
    transition: '0.4s ease',
    marginRight: 10,
    height: '270px',
    position: 'relative'
    // backgroundImage: `url(${'"/images/Group 241 (5).png"'})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // width:"110%"
  },

  doseInsights: {
    position: 'relative',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    marginBottom: 20,
    cursor: 'pointer',
    '& .MuiTypography-caption': {
      display: 'flex',
      alignItems: 'center',
      marginTop: 3,
      color: '#FF00A8',
      fontWeight: 600,
      '& img': {
        marginLeft: 5
      }
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
      marginBottom: 10,
      color: '#000',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      fontSize: 20,
      fontFamily: 'humanchain_bold !important'
    },
    '& .doseImg': {
      marginLeft: 10
    },
    '& .doseWrap': {
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
      // minHeight:150
    }
  },
  helpLearn: {
    borderRadius: 20,
    margin: 20,
    minHeight: 100,
    transition: '0.4s ease',
    background: '#99359D',
    '&:hover': {
      transform: 'scale(1.03)'
    },
    '& .MuiTypography-h6': {
      color: '#fff',
      marginBottom: 0
    },
    '& .MuiTypography-subtitle2': {
      color: '#fff',
      fontWeight: 500,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center'
    }
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  listOfmenus: {
    marginBottom: theme.spacing(2),
    padding: '10px 16px',
    borderRadius: 20,
    cursor: 'pointer',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#fff',
    transition: '0.4s ease',
    '&:hover': {
      // background: "#fad9f1",
      // border: "1.4px solid #f9cded",
      transform: 'scale(1.03)'
    }
  },
  iconList: {
    border: '2px solid #f2f2f2',
    padding: '10px 10px',
    borderRadius: 50,
    marginRight: 16,
    display: 'flex'
  },
  cardDescriptionImg: {
    minHeight: 100,
    padding: 20,
    color: '#fff',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none !important'
    // marginBottom: '80px'
  },
  mainTitle: {
    // padding: '0px 0px 20px 0px'
  },
  cardTitle: {
    display: 'flex',
    justify: 'start',
    alignItems: 'center'
  },
  menuDescription: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    fontSize: 20,
    fontFamily: 'humanchain_bold !important'
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  backimage2: {
    backgroundImage: `url(${'"/images/Mask Group 6.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  pd: {
    padding: 20
  },
  lists: {
    paddingBottom: 8,
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 13,
    '& img': {
      margin: 8
    }
  },
  moreoption: {
    color: '#5449D6',
    fontSize: 14,
    marginBottom: 4,
    fontWeight: 600,
    marginTop: 6
  },
  moreoptionclick: {
    color: '#FF00A8',
    fontSize: 13
  },
  privacypolicy: {
    height: '50px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: '1px solid #0000001f',
    cursor: 'pointer'
  },
  indexStyle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    fontStyle: 'italic',
    color: '#ff6dc5'
  },
  changesInitiative: {
    textAlign: 'center',
    '& img': {
      width: '64%'
    }
  },
  homeBanner: {
    '& .homeImg': {
      width: '100%'
    },
    '& img': {
      marginBottom: 30
    }
  }
})
class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    // console.log('home')
  }

  moveToFlow = data => {
    if (data !== 'login') {
      if (data.id === 1) {
        this.props.history.push(Routes.registrationType)
      } else {
        if (localStorage.userId) {
          this.props.history.push(Routes.requests)
        } else {
          this.props.history.push(Routes.mlogin)
        }
      }
    } else {
      localStorage.getItem('isSubscribed')
        ? this.props.history.push('/subscription_request')
        : localStorage.getItem('userId')
        ? this.props.history.push('/requests')
        : this.props.history.push(Routes.mlogin)
    }
  }

  render () {
    const { classes } = this.props
    const settings = {
      infinite: true,
      // autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true
      // swipeEvent:(swipeDirection)=>{
      //   console.log(swipeDirection)
      // },
      // onSwipe:(swipeDirection)=>{
      //   console.log(swipeDirection,'onSwipe')
      // },
    }
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header subTitle='Making an impact by bridging people' />
            <Grid className={classes.body}>
              <div className={classes.pd}>
                <Grid item className={classes.mainTitle}>
                  <Typography
                    variant={'h5'}
                    style={{ fontSize: 23, fontWeight: 600 }}
                  >
                    <img
                      alt='img'
                      src='/images/Group 43.svg'
                      style={{
                        marginRight: -30
                      }}
                    />
                    Enabling humanity, together.
                  </Typography>
                </Grid>
              </div>
              {/* Description card */}
              <Slider {...settings}>
                {adList.map((val, i) => {
                  return (
                    <div>
                      <Card
                        className={`${classes.listOfDes}`}
                        elevation={4}
                        key={i}
                      >
                        <img
                          alt='img'
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute'
                          }}
                          src='/images/Group 241 (5).png'
                        />
                        <div
                          style={{
                            padding: '10px 14px'
                          }}
                        >
                          <Typography
                            variant={'body1'}
                            component='div'
                            // color={'primary'}
                            className={classes.indexStyle}
                          >
                            {`#${i + 1}`}
                          </Typography>
                          <Grid item className={classes.cardTitle}>
                            <Typography
                              variant={'body1'}
                              // color={'primary'}
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                marginBottom: 8
                              }}
                            >
                              {val.name}
                            </Typography>
                          </Grid>
                          <div>
                            {val?.subName && (
                              <Typography
                                variant='subtitle2'
                                style={{ marginBottom: 5 }}
                                color={'textSecondary'}
                              >
                                {val?.subName}
                              </Typography>
                            )}
                            <Typography
                              variant={'subtitle2'}
                              color={'textSecondary'}
                              style={{ fontSize: '12px' }}
                            >
                              <div className={classes.lists}>
                                {val?.isbullet && (
                                  <img
                                    alt='img'
                                    width='8px'
                                    src='./images/icons8-small-blue-diamond.svg'
                                  />
                                )}
                                {val.dis1}
                              </div>
                              <div className={classes.lists}>
                                {val?.isbullet && (
                                  <img
                                    alt='img'
                                    width='8px'
                                    src='./images/icons8-small-blue-diamond.svg'
                                  />
                                )}
                                {val.dis2}
                              </div>
                              {val.dis3 && (
                                <div className={classes.lists}>
                                  {val?.isbullet && (
                                    <img
                                      alt='img'
                                      width='8px'
                                      src='./images/icons8-small-blue-diamond.svg'
                                    />
                                  )}
                                  {val.dis3}
                                </div>
                              )}
                              {val.dis4 && (
                                <div className={classes.lists}>
                                  {val?.isbullet && (
                                    <img
                                      alt='img'
                                      width='8px'
                                      src='./images/icons8-small-blue-diamond.svg'
                                    />
                                  )}
                                  {val.dis4}
                                </div>
                              )}
                              {i === 3 && (
                                <>
                                  {/* <Typography className={classes.moreoption}>
                                  Learn how you can help in other ways.
                                  </Typography>
                                  <Typography
                                    variant={'subtitle2'}
                                    className={classes.moreoptionclick}
                                  >
                                    {'Click here'}
                                    <ArrowForwardIosRoundedIcon
                                      style={{
                                        fontSize: 12,
                                        marginLeft: 5,
                                        color: '#FF00A8',
                                        marginBottom: -2
                                      }}
                                    />
                                  </Typography> */}
                                </>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )
                })}
              </Slider>
              {/* Type of request list */}

              <div className={classes.pd}>
                {requestList.map((val, i) => {
                  return (
                    <Card
                      className={
                        classes.listOfmenus +
                        ' ' +
                        val.styleClass +
                        ' ' +
                        `${i === 0 ? classes.backimage1 : classes.backimage2}`
                      }
                      elevation={4}
                      key={i}
                      style={{
                        marginBottom: i === requestList.length - 1 && 0
                      }}
                      onClick={() =>
                        i === 1
                          ? this.props.history.push(
                              Routes.subscriptionCategorys
                            )
                          : this.moveToFlow(val)
                      }
                    >
                      {/* <img src='/images/Mask Group 5.png' /> */}
                      <div>
                        <Grid item className={classes.cardTitle}>
                          <Typography
                            variant={'body1'}
                            className={classes.title}
                          >
                            {val.name}
                          </Typography>
                        </Grid>
                        <div>
                          <Typography
                            variant={'subtitle2'}
                            className={classes.menuDescription}
                          >
                            {val.subText}
                            <ArrowForwardIosRoundedIcon
                              style={{ fontSize: 12, marginLeft: 5 }}
                            />
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </div>
              <div>
                {/* <Link href="https://dose.onesoul.care/onesoul/subcategory/ways-to-help-on-onesoul-035d9ae265be" target="_blank" className={classes.link}> */}
                <Card
                  elevation={4}
                  className={`${classes.doseInsights} ${classes.helpLearn} ${classes.backimage1}`}
                  style={{ marginTop: 0 }}
                  onClick={() => this.moveToFlow('login')}
                >
                  <img
                    alt='img'
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute'
                    }}
                    src='/images/Group 241 (5).png'
                  />
                  <div className='doseWrap'>
                    <div>
                      <Typography variant='h6'>Sign In</Typography>
                      <Typography variant='subtitle2'>
                        Provide mobile number and view
                        {/* <img alt="rightArrow" src="images/circled-down-right-2.svg"/> */}
                        <ArrowForwardIosRoundedIcon
                          style={{ fontSize: 12, marginLeft: 5 }}
                        />
                      </Typography>
                    </div>
                    {/* <img className={'helpingHand'} alt='helpingHand' src={'images/helping-hand (2).svg'} /> */}
                  </div>
                </Card>

                {/* </Link> */}
                <Link
                  href='https://dose.onesoul.care/covid-19'
                  target='_blank'
                  className={classes.link}
                >
                  <div className={classes.doseInsights}>
                    <img
                      alt='img'
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute'
                      }}
                      src='/images/Group 241 (5).png'
                    />
                    <div className='doseWrap'>
                      <div>
                        <Typography variant='h6'>
                          Awareness Dose for Covid&shy;-19
                        </Typography>
                        <Typography variant='caption'>
                          Click to swipe and learn
                          {/* <img alt="rightArrow" src="images/circled-down-right-2.svg"/> */}
                          <ArrowForwardIosRoundedIcon
                            style={{ fontSize: 12, marginLeft: 5 }}
                          />
                        </Typography>
                      </div>
                      <img
                        className={'doseImg'}
                        alt='doseImg'
                        src={'images/Group 273.svg'}
                      />
                    </div>
                  </div>
                </Link>
              </div>
              <Link
                href='https://www.crayond.com/'
                target='_blank'
                className={classes.link}
              >
                <div className={classes.homeBanner}>
                  <img
                    src='images/home_banner.jpg'
                    alt='home'
                    className='homeImg'
                  />
                  <div className={classes.changesInitiative}>
                    <img src='images/Change_Initiative.png' alt='Crayond' />
                  </div>
                </div>
              </Link>
              <div
                className={classes.privacypolicy}
                onClick={() => this.props.history.push(Routes.privacypolicy)}
              >
                <div style={{ marginTop: 20 }}>Privacy Policy</div>
                <Typography
                  variant='caption'
                  color='textSecondary'
                  align='center'
                  style={{ width: '100%' }}
                >
                  {localStorage.getItem(localStorageKeys.version)
                    ? `v${localStorage.getItem(localStorageKeys.version)}`
                    : 'v0.0.0'}
                </Typography>
              </div>
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Home))
