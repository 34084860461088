import React from 'react';
import { makeStyles,FormControl,FormControlLabel,RadioGroup,Radio,Typography  } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root:{
        '& .MuiFormControlLabel-label':{
            textTransform:'capitalize'
        }
    }
}));
const RadioButton = ({
    option = [],
    value = {},
    handleChange = false,
    direction = 'column',
    heading,
    isReadonly=false,
    isrequired
}) => {
    const classes = useStyles();
    return (
       <div className={classes.root}>
           <Typography variant="body1">{heading} {isrequired && <Typography variant="caption" style={{color:"red"}}>*</Typography>}</Typography>
           <FormControl component="fieldset">
            <RadioGroup
                // aria-label="gender"
                // name="gender1"
                value={value.value}
                onChange={(value)=>handleChange({value:value.target.value,label:value.target.value})}
                style={{
                    flexDirection:direction
                }}
                >
                {
                    option && option.map((data)=>{
                        return(
                            <FormControlLabel value={data.value} control={<Radio color="primary" disabled={isReadonly ?? false} />} label={data.label} />
                        )
                    })
                }
                </RadioGroup>
           </FormControl>
       </div>
    )
}
RadioButton.propTypes = {
    option: PropTypes.array, // EX: [{value: "", label: ""}],
    value:PropTypes.object, // Ex {value: "", label: ""}
    handleChange:PropTypes.func,
    direction:'row'|'column',
    heading:PropTypes.string
}

export default RadioButton;

// this.state = {
//     value:{
//       value:'female',
//       label:'Female',
//     }
//   }

// options = [
//     {
//       value:'male',
//       label:'male',
//     },
//     {
//       value:'female',
//       label:'female',
//     },
//     {
//       value:'others',
//       label:'others',
//     },
//   ];
  

//   handleChange = (event) => {
    
//     this.setState({
//       ...this.state,value:{
//         value:event.target.value,
//         label:event.target.value
//       }
//     });
//   };

//   <RadioButton 
//     option={this.options}
//     value={this.state.value}
//     handleChange={this.handleChange}
//     direction="row"
// />