export const Routes = {
  //Auth routes
  home: "/",
  mlogin: "/mobileLogin",
  registrationType: "/registrationtype",
  needs: "/needs",
  registrationFormParent: "/registrationform/",
  registrationForm: "/registrationform/:request_id/:id",
  otpVerify: "/verify",
  sharePost: "/share",
  requests: "/requests",
  OtpVerifyResponse: "/shareVerify",
  reOpenVerify: "/expiredVerify",
  willinghelp: "/willinghelp",
  comments: "/comments",
  requestVerificationParent: "/request_verification/",
  requestVerification: "/request_verification/:id",
  form_renderer: '/renderer',
  sharedRequest: "/:id",
  sharedRequestuse: "/request/",
  notification: "/notification",
  profile: "/profile",
  privacypolicy: '/privacypolicy',
  subscriptionCategorys: '/subscription_categorys',
  subscriptionrequest:'/subscription_request'
};