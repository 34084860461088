export const setLocal = (name, value) => {
    let localState = localStorage.lst ? JSON.parse((localStorage.lst)) : {};
    localState[name] = value;
    localStorage.setItem("lst", JSON.stringify(localState))
}

export const getLocal = (name) => {
    let localState = localStorage.lst ? JSON.parse((localStorage.lst)) : {};
    return localState[name]
}


export const removeLocalstate = (name) => {
    localStorage.removeItem("lst")
}

export const RequestData = (request) => {
    let type = {
        mention: ["Mentions"],
        age: ["Age"],
        gender: ["Gender"],
        mno: ["Mobile Number"],
        address: ["Location"],
        name: ["Name"],
        ems: ["Emergency Status"],
        medicine: ["Medicine Required"],
        cantactnumber: ["Contact Number"],
        needby: ["Need By"],
        bloodtype: ["Blood Type"],
        bloodgrp: ["Blood Group"],
        units: ["Units"],
    }
    if (!request) {
        return {}
    }
    try {
        let arr = Object.values(JSON.parse(request))
        let list = {};
        arr.map(val => {
            Object.keys(type).map(k => {
                if (type[k].indexOf(val.tag) > -1) {
                    list[k] = val.answer
                }
                return false
            })
            return false
        })
        list.mention = list.mention?.map(val => val.label).join(", ") ?? ""
        list.medicine = list.medicine?.map(val => val.label).join(", ") ?? ""
        list.location = list.address;
        list.address = list.address.address;
        list.ems = list.ems.toString().toLocaleLowerCase() === "yes" ? true : false
        return list;
    } catch (e) {
        return {};
    }
}