import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
// import { Routes } from "../../router/routes";
import OtpVerifyComonent from '../../components/otpComponent'
import CONFIG from '../../config'
import { AlertProps, GiveMeShareURL, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { Routes } from '../../router/routes'
import { withAllContexts } from '../../HOCs'
import { removeLocalstate, RequestData } from '../../funcations/common'

const styles = theme => ({})
class OtpVerify extends React.Component {
  constructor (props) {
    super(props)
    this.state = { otp: '', name: '' }
  }

  componentDidMount () {
    const requestDatas = this.props.location.state
    try {
      let json = RequestData(JSON.stringify(requestDatas?.answer))
      this.setState({ name: json.name })
    } catch (e) {}
  }

  handleChange = otp => this.setState({ otp })

  handleSuccess = () => {
    this.postRequest()
  }

  postRequest = async () => {
    if (this.props.location.state.selectCategorys) {
      this.props.history.push('/subscription_request')
      return
    }
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: 'Saving your request...'
    })

    const requestData = this.props.location.state

    let body = {
      request_type: requestData?.request_type_id,
      form_id: requestData?.form_id,
      request: requestData?.answer,
      requested_by: localStorage.getItem('userId'),
      timeStamp: new Date().toISOString()
    }

    NetworkCall(
      CONFIG.api_url + 'request/upsert',
      NetWorkCallMethods.post,
      body
    )
      .then(res => {
        if (res?.data && res?.data?.type === 'Success') {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false
          })

          removeLocalstate()

          this.props.history.push({
            pathname: Routes.sharePost,
            state: {
              ...this.props?.location?.state,
              copyLink:
                window.location.origin +
                '/' +
                GiveMeShareURL(
                  RequestData(JSON.stringify(requestData?.answer)),
                  requestData.requestTypeName,
                  res?.data?.request_id
                )
            }
          })
        } else {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false
          })
          this.props.alert.setSnack({
            ...this.props.alert.alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: 'Something went wrong, Unable to post request',
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        }
      })
      .catch(error => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false })
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Something went wrong, Unable to post request',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
  }

  render () {
    const { classes } = this.props
    const { mobileNo } = this.props.location.state
    const { otp, name } = this.state
    return (
      <div className={classes.root}>
        <OtpVerifyComonent
          value={otp}
          userName={name}
          locatioState={this.props.location.state}
          mobileNo={mobileNo}
          handleChange={this.handleChange}
          successFun={this.handleSuccess}
        />
      </div>
    )
  }
}

export default withAllContexts(withRouter(withStyles(styles)(OtpVerify)))
