import React from 'react'
import {
  Grid,
  withStyles,
  Typography,
  Checkbox,
  Card,
  Button,
  Link
} from '@material-ui/core'
// import { localStorageKeys } from '../../utils';
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
//components
import { Header, LoadingSection } from '../../components'
import { withApollo } from 'react-apollo'
import { getAllMasterFormTypes, getProfile } from '../../graphql/queries'
import { catUpdate } from '../../graphql/mutations'
import Skeleton from '@material-ui/lab/Skeleton'
import { DialogComponent } from '../../components/dialog/index'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { AlertProps } from '../../utils'
import { withAllContexts } from '../../HOCs'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    minHeight: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%',
      minHeight: '100vh'
    },
    position: 'relative'
  },
  cardStyle: {
    minHeight: 80,
    height: 80,
    display: 'flex',
    // flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    // border: '1px solid transparent',
    // padding: theme.spacing(1),
    // height: '100%',
    transition: '0.4s ease',
    borderRadius: 10,
    '&:hover': {
      background: '#fad9f1',
      transform: 'scale(1.03)'
    },
    position: 'relative'
  },
  title: {
    // marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20,
    padding: '20px 20px 0px 20px'
  },
  activeCard: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  item: {
    padding: 6
  },
  labelStyle: {
    textAlign: 'left',
    fontSize: 16,
    width: '-webkit-fill-available'
  },
  body: {
    // padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 64px)',
      marginTop: 60
    }
  },
  icons: {
    marginBottom: theme.spacing(1)
  },
  imgStyle: {
    width: '100%',
    height: 82,
    objectFit: 'none',
    boxShadow: '3px 3px 6px #FF00A81A',
    // borderRadius: 20
  },
  btn: {
    height: '40px',
    borderRadius: 10,
    marginTop: '10px',
    width: '80%',
    display: 'flex',
    margin: 'auto'
  },
  clk: {
    color: '#FF00A8',
    fontWeight: 500,
    display: 'flex',
    margin: 'auto',
    marginTop: 6,
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 14,
    '& .icon': {
      fontSize: 18,
      marginLeft: 4,
      marginTop: 1
    }
  },
  link: {
    width: '100%'
  },
  footer: {
    // display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    height: '88px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#fff',
    // color: '#e72ea6',
    color: '#000',
    // borderTopLeftRadius: 16,
    // borderTopRightRadius: 16,
    padding: '0px 10px',
    cursor: 'pointer',
    borderTop: '1px solid #d3d3d333',
    '& svg': {
      marginRight: 6
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1000,
      boxShadow: 'none'
    }
  }
})
class SubscriptionCategorys extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeCard: null,
      cardList: [],
      selectCategorys: [],
      open: false,
      valueData: {}
    }
  }

  componentDidMount () {
    if (
      this.props.location?.state?.feed === 'feeds_update' ||
      localStorage.getItem('isSubscribed')
    ) {
      this.props.client
        .query({
          query: getProfile,
          variables: { id: localStorage.userId },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          //   array-callback-return
          if (res?.data?.userProfileById) {
            this.setState({
              selectCategorys: res?.data?.userProfileById?.subscripedCategories
            })
          }
        })
    }
    const img = {
      0: '/images/adhy-savala-zbpgmGe27p8-unsplash@2x.png',
      1: '/images/mockup-graphics-2WlwSXFw7Kk-unsplash@2x.png',
      2: '/images/martha-dominguez-de-gouveia-KF-h9HMxRKg-unsplash@2x.png',
      3: '/images/young-doctor-supporting-his-patient@2x.png',
      4: '/images/high-angle-boy-with-oxygen-mask@2x.png',
      5: '/images/fried-rice-plate-white-wooden-floor@2x.png',
      6: '/images/medicines-medical-supplies-placed-blue@2x.png',
      7: '/images/closeup-blood-tube-test@2x.png',
      8: '/images/others-category.png'
    }
    this.props.client
      .query({
        query: getAllMasterFormTypes,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        //   array-callback-return
        const cardList = res.data.allMasterRequestTypes.nodes?.map((v, i) => {
          v.imageUrl = img[i]
          return v
        })
        this.setState({ cardList })
      })
  }
  Updatecategray = () => {
    this.props.client
      .mutate({
        mutation: catUpdate,
        variables: {
          id: localStorage.userId,
          subscripedCategories: this.state.selectCategorys
        }
      })
      .then(data => {
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: 'Thanks for updating!',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
        this.props.history.push('/subscription_request')
      })
      .catch(error => {
        console.log(error)
      })
  }
  onSubmit = async (val, index) => {
    this.setState({
      ...this.state,
      activeCard: index
    })
    this.props.history.push({
      pathname: Routes.registrationFormParent + 12 + '/' + 12,
      state: {
        selectCategorys: this.state.selectCategorys,
        selectCategorysuser: true,
        subscriptionUser: 12
      }
    })
  }
  handleSelect = (v, i) => {
    const state = this.state
    if (state.selectCategorys.includes(v?.id)) {
      const filterS = state.selectCategorys.filter(l => {
        if (l !== v.id) {
          return l
        }
      })
      this.setState({
        ...state,
        selectCategorys: filterS,
        valueData: v
      })
    } else {
      state.selectCategorys.push(v?.id)
      this.setState({
        ...state,
        valueData: v
      })
    }
  }
  handleopen = () => {
    const state = this.state
    this.setState({
      ...state,
      open: !state.open
    })
  }
  render () {
    const { classes } = this.props
    const { cardList, open, selectCategorys } = this.state
    let imageUrl =
      'https://arizent.brightspotcdn.com/90/23/bbdfb76e46f28568617041dd7141/hill-rom-centrella-smart-bed-crop.png'
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography variant='h6' className={classes.title}>
                {'How can you help?'}
              </Typography>

              <Grid
                container
                item
                style={{
                  padding: 4,
                  marginBottom: '100px',
                  justifyContent:
                    !cardList?.length > 0 ? 'center' : 'space-between'
                }}
              >
                {!cardList?.length > 0 && <LoadingSection />}
                {cardList?.length > 0 && (
                  <>
                    {cardList.map((l, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          key={l.value}
                          className={classes.item}
                        >
                          <Card
                            elevation={4}
                            className={`${classes.cardStyle} ${this.state
                              .activeCard === index && classes.activeCard}`}
                            onClick={e => this.handleSelect(l, index)}
                          >
                            <Checkbox
                              color='primary'
                              checked={
                                selectCategorys?.includes(l.id) ? true : false
                              }
                            />
                            <Typography
                              variant='h6'
                              className={classes.labelStyle}
                            >
                              {l.label}
                            </Typography>
                            <div
                              style={{
                                width: 150,
                                right: 0,
                                // position: 'absolute'
                              }}
                            >
                              {l.imageUrl ? (
                                <img
                                  className={classes.imgStyle}
                                  src={l.imageUrl ? l?.imageUrl : imageUrl}
                                  alt={l?.imageUrl}
                                />
                              ) : (
                                <Skeleton
                                  animation='wave'
                                  variant='rect'
                                  width={'100%'}
                                  height={140}
                                />
                              )}
                            </div>
                          </Card>
                        </Grid>
                      )
                    })}
                  </>
                )}
                <DialogComponent
                  title={
                    <div>
                      The platform is built to serve people, please do not
                      misuse.
                      <div style={{ marginTop: 6 }}>
                        Tap on yes to agree and proceed.
                      </div>
                    </div>
                  }
                  open={open}
                  onOk={
                    this.props.location?.state?.feed === 'feeds_update' ||
                    localStorage.getItem('isSubscribed')
                      ? this.Updatecategray
                      : this.onSubmit
                  }
                  onCancel={this.handleopen}
                  positiveBtn='Yes'
                  negativeBtn='No'
                />
              </Grid>
            </Grid>
            <div className={classes.footer}>
              {cardList?.length > 0 && (
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={this.handleopen}
                  className={classes.btn}
                  disabled={
                    this.state.selectCategorys.length > 0 ? false : true
                  }
                >
                  {this.props.location?.state?.feed === 'feeds_update' ||
                  localStorage.getItem('isSubscribed')
                    ? 'Update'
                    : 'Continue'}
                </Button>
              )}
              {cardList?.length > 0 && (
                <Link
                  href='https://dose.onesoul.care/onesoul/subcategory/ways-to-help-on-onesoul-035d9ae265be'
                  target='_blank'
                  className={classes.link}
                >
                  <Typography className={classes.clk}>
                    Click to learn how you can help
                    <ErrorOutlineIcon className='icon' />
                  </Typography>
                </Link>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(
  withStyles(styles)(withAllContexts(withApollo(SubscriptionCategorys)))
)
