import React from "react";
import { Card, Typography, IconButton, makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {},
  cardStyle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
  },
}));

export const CardList = ({ title = "Title", subTitle = "Sub Title" }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleNavigation = () => {
    history.push({
      pathname: Routes.registrationType,
    });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.cardStyle} onClick={handleNavigation}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subTitle}>
          {subTitle}
          <IconButton>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </Typography>
      </Card>
    </div>
  );
};

CardList.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
