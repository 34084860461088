import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import OtpVerifyComonent from '../../components/otpComponent'
import { withAllContexts } from '../../HOCs'
import CONFIG from '../../config'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { Routes } from '../../router/routes'
import { removeLocalstate } from '../../funcations/common'

const styles = theme => ({})
class OtpVerifyResponse extends React.Component {
  constructor (props) {
    super(props)
    this.state = { otp: '' }
  }

  handleChange = otp => this.setState({ otp })

  handleSuccess = () => {
    this.postRequest()
  }

  postRequest = async () => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: 'Saving your request...'
    })

    const requestData = this.props.location.state

    let body = {
      request_id: requestData?.request_id,
      response_type: requestData?.response_type,
      form_id: requestData?.form_id,
      response: requestData?.answer
    }
    NetworkCall(
      CONFIG.api_url + 'response/upsert',
      NetWorkCallMethods.post,
      body
    )
      .then(res => {
        if (res?.data && res?.data?.type === 'Success') {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false
          })
          removeLocalstate()
          this.props.history.push({
            pathname: Routes.sharePost,
            state: {
              ...this.props?.location?.state,
              copyLink: '/_' + res?.data?.request_id,
              reqID: this.props.location.state.reqID
            }
          })
        } else {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false
          })
          this.props.alert.setSnack({
            ...this.props.alert.alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: 'Something went wrong, Unable to post request',
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        }
      })
      .catch(error => {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false })
        this.props.alert.setSnack({
          ...this.props.alert.alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: 'Something went wrong, Unable to post request',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      })
  }

  render () {
    const { classes } = this.props
    const { mobileNo } = this?.props?.location?.state ?? {}
    const { otp } = this.state
    return (
      <div className={classes.root}>
        <OtpVerifyComonent
          value={otp}
          mobileNo={mobileNo}
          handleChange={this.handleChange}
          successFun={this.handleSuccess}
        />
      </div>
    )
  }
}

export default withAllContexts(
  withRouter(withStyles(styles)(OtpVerifyResponse))
)
