import React from 'react';
import { makeStyles, Avatar,Typography,Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
   
      postStyle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign:'center',
        '& .MuiTypography-h6':{
            marginBottom:theme.spacing(1)
        }
      },
      large:{
        width:200,
        height:200,
        border:'1px solid lightgrey',
      },
  }));
  

export const Success = ({
    imgUrl='',
    title='',
    subTitle='',
    buttonText='',
    buttonAction=null,
}) =>{

    const classes = useStyles();

   return(
      <React.Fragment>
        {imgUrl && <Avatar alt="Remy Sharp" src={imgUrl} className={classes.large} /> }
        <div className={classes.postStyle}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{subTitle}</Typography>
        </div>
          {
              buttonText.length > 0 && <Button fullWidth variant="contained" color="primary" onClick={buttonAction && buttonAction}>{buttonText}</Button>
          }
          </React.Fragment>
   )
}