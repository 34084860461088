import React from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
// import { localStorageKeys } from '../../utils';
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
//components
import { Header, LoadingSection } from '../../components'
import { withApollo } from 'react-apollo'
import { getAllMasterFormTypes } from '../../graphql/queries'
import Skeleton from '@material-ui/lab/Skeleton'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    boxShadow: '0 0 1em 2px #00000040',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  cardStyle: {
    minHeight: 150,
    // display: "flex",
    // flexDirection:'column',
    // alignItems: "center",
    // justifyContent: "center",
    cursor: 'pointer',
    border: '1px solid transparent',
    padding: theme.spacing(1),
    height: '100%',
    transition: '0.4s ease',
    borderRadius: 20,
    '&:hover': {
      background: '#fad9f1',
      transform: 'scale(1.03)'
    }
  },
  title: {
    // marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 20,
    padding: '20px 20px 0px 20px'
  },
  activeCard: {
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  item: {
    padding: 4
  },
  labelStyle: {
    textAlign: 'center',
    fontSize: 16
  },
  body: {
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  icons: {
    marginBottom: theme.spacing(1)
  },
  imgStyle: {
    width: '100%',
    height: 160,
    objectFit: 'none',
    boxShadow: '3px 3px 6px #FF00A81A',
    borderRadius: 20
  }
})
class Needs extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeCard: null,
      cardList: []
    }
  }

  componentDidMount () {
    const img = {
      0: '/images/adhy-savala-zbpgmGe27p8-unsplash@2x.png',
      1: '/images/mockup-graphics-2WlwSXFw7Kk-unsplash@2x.png',
      2: '/images/martha-dominguez-de-gouveia-KF-h9HMxRKg-unsplash@2x.png',
      3: '/images/young-doctor-supporting-his-patient@2x.png',
      4: '/images/high-angle-boy-with-oxygen-mask@2x.png',
      5: '/images/fried-rice-plate-white-wooden-floor@2x.png',
      6: '/images/medicines-medical-supplies-placed-blue@2x.png',
      7: '/images/closeup-blood-tube-test@2x.png',
      8: '/images/others-category.png'
    }
    this.props.client
      .query({
        query: getAllMasterFormTypes,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const cardList = res.data.allMasterRequestTypes.nodes?.map((v, i) => {
          v.imageUrl = img[i]
          return v
        })
        this.setState({ cardList })
      })
  }

  handleNavigation = async (val, index) => {
    this.setState({
      ...this.state,
      activeCard: index
    })
    let { selectedState } = this.props?.location?.state
    selectedState = selectedState ? selectedState : {}

    let formId = selectedState?.ismyselef === 0 ? val.form : val.othersForm

    this.props.history.push({
      pathname: Routes.registrationFormParent + val.value + '/' + formId,
      state: {
        ...selectedState,
        needs: val,
        requestTypeName: val.label
      }
    })
  }

  render () {
    const { classes } = this.props
    const { cardList } = this.state
    let imageUrl =
      'https://arizent.brightspotcdn.com/90/23/bbdfb76e46f28568617041dd7141/hill-rom-centrella-smart-bed-crop.png'
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography variant='h6' className={classes.title}>
                {'What are you looking for ?'}
              </Typography>

              <Grid
                container
                item
                style={{
                  padding: 4,
                  marginBottom: '80px',
                  justifyContent:
                    !cardList?.length > 0 ? 'center' : 'space-between'
                }}
              >
                {!cardList?.length > 0 && <LoadingSection />}
                {cardList?.length > 0 && (
                  <>
                    {cardList.map((l, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          key={l.value}
                          className={classes.item}
                        >
                          <div
                            elevation={4}
                            className={`${classes.cardStyle} ${this.state
                              .activeCard === index && classes.activeCard}`}
                            onClick={() => this.handleNavigation(l, index)}
                          >
                            <div>
                              {l.imageUrl ? (
                                <img
                                  className={classes.imgStyle}
                                  src={l.imageUrl ? l?.imageUrl : imageUrl}
                                  alt={l?.imageUrl}
                                />
                              ) : (
                                <Skeleton
                                  animation='wave'
                                  variant='rect'
                                  width={'100%'}
                                  height={140}
                                />
                              )}
                              <Typography
                                variant='h6'
                                className={classes.labelStyle}
                              >
                                {l.label}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      )
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(Needs)))
