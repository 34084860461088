import React, { useState } from "react";
import {
 makeStyles,
 TableContainer,
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableRow,
 Typography,
 Button,
 Checkbox,
 TextField,
 IconButton,
 Popover,
 List,
 ListItem,
 ListItemIcon,
 ListItemText
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import TextFieldsOutlinedIcon from '@material-ui/icons/TextFieldsOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
// import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head':{
      backgroundColor:'#E5EAF4',
      padding:'5px 16px',
      minWidth:150,
    }
  },
  addColumn:{
    '& .MuiIconButton-root':{
      borderRadius:0,
      '&:hover':{
       backgroundColor:'transparent',
      }
    }
  }
}));
const CustomTableFixedRow = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectIndex,setSelectIndex] = React.useState('');

  const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
  };

  const openPopover = (event,index) =>{
   setSelectIndex(index)
   handleClick(event)
  }

  const handleClose = () => {
    setSelectIndex('')
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
   const [toggleInput,setToggleInput] = useState({
     index:'',
     toggle:false
   });
   
   const [inputValue,setInputValue] = useState('');

   const [toggleRowInput,setToggleRowInput] = useState({
    index:'',
    toggle:false
  });

  const uiComponentPopover=[
    {component_name:'Checkbox',ui_component:'check_box',icon:'CheckBoxOutlinedIcon'},
    {component_name:'Input Text',ui_component:'input_text',icon:'TextFieldsOutlinedIcon'},
    {component_name:'Date',ui_component:'date',icon:'CalendarTodayOutlinedIcon'},
  ]

  const giveMeIcon = (icon) =>{
    switch(icon){
      case 'CheckBoxOutlinedIcon':
        return <CheckBoxOutlinedIcon />
      case 'TextFieldsOutlinedIcon':
        return <TextFieldsOutlinedIcon />
      case 'CalendarTodayOutlinedIcon':
        return <CalendarTodayOutlinedIcon />
        default:
          return <CheckBoxOutlinedIcon />
    }
  }

   const addColumn = (col) =>{
     // 
     if(selectIndex!=='' && selectIndex >= 0){
       Object.assign(props?.data?.tableScheme.column[selectIndex], col)
     }
     else{
       props?.data?.tableScheme?.column.push({
         name:'Column Type',
         ...col
       })
     }
     handleClose()
     props?.onChangeComponents(props?.data?.tableScheme);
   }

   const deleteColumn = () =>{
     const filteredData = props?.data?.tableScheme?.column.filter((l,i)=>i!==selectIndex)
     props.data.tableScheme.column=filteredData;
     handleClose()
     props?.onChangeComponents(props?.data?.tableScheme);
   }

   const addRow = () =>{
     props?.data?.tableScheme?.row.push({
       name:'Row Type'
     })
     props?.onChangeComponents(props?.data?.tableScheme);
   }

   const clickColumnName = (columnData,index) =>{
     setToggleInput({
       toggle:true,
       index
     })
     setInputValue(columnData.name)
   }

   const clickRow = (rowData,index) =>{
    setToggleRowInput({
       toggle:true,
       index
     })
     setInputValue(rowData.name) 
   }

   const onChangeColumnName = (value,index) =>{
     setInputValue(value);
     props.data.tableScheme.column[index].name=value
     props?.onChangeComponents(props?.data?.tableScheme);
   }

   const blur = () =>{
     setToggleInput({
       ...toggleInput,toggle:false,index:''
     });
     setToggleRowInput({
        ...toggleRowInput,toggle:false,index:''
      })
     setInputValue('');
   }
  
   const onChangeText = (index, value) =>{
    setInputValue(value);
    props.data.tableScheme.row[index].name=value
    props?.onChangeComponents(props?.data?.tableScheme);
   }

  return (
    <div className={classes.root}>
         <TableContainer>
           <Table aria-label="simple table">
           <TableHead>
               <TableRow>
                   {
                     props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData,index)=>{
                       return(
                         <TableCell key={index} variant="head">
                           <div style={{display:'flex',alignItems:'center'}}>
                             <Typography variant="body1" onClick={()=>clickColumnName(colData,index)}>
                                 {toggleInput.toggle && toggleInput.index===index  ? (
                                   <TextField 
                                   autoFocus
                                   value={inputValue}
                                   onChange={(e) => onChangeColumnName(e.target.value,index)}
                                   onBlur={blur}
                               />
                                 ) : colData.name}
                             </Typography>
                            { index > 0 &&
                             <IconButton size="small" aria-describedby={id} onClick={(event)=>openPopover(event,index)}>
                                   <ExpandMoreOutlinedIcon />
                             </IconButton>
                            }
                             </div>
                         </TableCell>
                       )
                     })
                   }
                   <TableCell>
                 <Button variant="text" color="secondary" className={classes.addColumn}>
                     <IconButton aria-describedby={id} onClick={handleClick} disableTouchRipple disableFocusRipple disableRipple>
                     <Typography color="secondary">Add Column &nbsp;</Typography>
                       <AddCircleOutlineOutlinedIcon color="secondary"/>
                     </IconButton>
                   <Popover
                       id={id}
                       open={open}
                       anchorEl={anchorEl}
                       onClose={handleClose}
                       anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                       }}
                       transformOrigin={{
                         vertical: "top",
                         horizontal: "center",
                       }}
                     >
                       <List>
                         {
                           uiComponentPopover && uiComponentPopover.map(l=>{
                             return(
                               <ListItem button onClick={()=>addColumn(l)}>
                               <ListItemIcon>
                                 {giveMeIcon(l.icon)}
                               </ListItemIcon>
                               <ListItemText primary={l.component_name} />
                             </ListItem>
                             )
                           })
                         }
                         {
                           selectIndex && parseInt(selectIndex) > 0  ?(
                           <ListItem button onClick={()=>deleteColumn()}>
                             <ListItemIcon>
                               <DeleteOutlineOutlinedIcon/>
                             </ListItemIcon>
                             <ListItemText primary={'Delete Column'} />
                           </ListItem>):null
                         }
                       </List>
               </Popover>
               </Button>
               </TableCell>
               </TableRow>
           </TableHead>
           <TableBody>
             {
               props?.data?.tableScheme?.row && props?.data?.tableScheme?.row?.map((rowData,i1)=>{
                 return(
                   <TableRow key={i1}>
                     {
                       props?.data?.tableScheme?.column && props?.data?.tableScheme?.column?.map((colData,i2)=>{
                         switch (colData.ui_component) {
                           case 'check_box':
                             return(
                                 <TableCell key={i2}>
                                     <Checkbox 
                                         // checked={false}
                                         // onChange={handleChange}
                                         inputProps={{ 'aria-label': 'primary checkbox' }}
                                     />
                                 </TableCell>
                             )
                             case 'input_text':
                             return(
                                <TableCell key={i2}>
                                      <TextField 
                                        // value={rowData[l][d].value}
                                        // onChange={(value) => onChange(index, data, value)}
                                        // label={rowData[l][d].label}
                                    />
                                  </TableCell>
                             )
                             case 'date':
                              return(
                                <TableCell key={i2}>
                                   <form noValidate>
                                      <TextField
                                        id="date"
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        // onChange={(e)=>console.log(e.target.value)}
                                      />
                                    </form>
                                </TableCell>
                              )
                             default:
                               return(
                                 <TableCell key={i2}>
                                    {
                                        toggleRowInput.toggle && toggleRowInput.index===i1  ? (
                                        <TextField 
                                        autoFocus
                                        value={inputValue}
                                        onChange={(event) => onChangeText(i1,event.target.value)}
                                        onBlur={blur}
                                    />
                                        ) : (
                                        <Typography onClick={()=>clickRow(rowData,i1)}>
                                            {rowData.name}
                                        </Typography>
                                        )
                                    }
                                 </TableCell>
                             )
                         }
                       })
                     }
                   </TableRow>
                 )
               }) 
             }
               <Button variant="text" color="secondary"  onClick={addRow}>
                 <Typography>Add Row</Typography>
                 <IconButton>
                   <AddCircleOutlineOutlinedIcon color="secondary" />
                 </IconButton>
             </Button>
           </TableBody>
           </Table>
         </TableContainer>
    </div>
  );
};
//  CustomTableFixedRow.propTypes = {

//  };
export default CustomTableFixedRow;

